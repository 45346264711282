import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NewModel } from 'components/Modal'
import { Input } from 'components/input/input'
import { useTheme, IconButton, InputBase, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate } from 'react-router-dom'
import FlexBetween from 'components/FlexBetween'
import { Search } from '@mui/icons-material';
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ThreeDots } from 'react-loader-spinner'
import { MdDelete, MdRemoveRedEye, MdChat, MdEdit, MdRefresh } from 'react-icons/md'
import { deleteInquiries, getAllInquiries, updateInqStatus, updateInquiry } from 'actions/inquiryActions'
import { clearInqChat, deleteChatMsg, getAllChats, submitChat } from 'actions/chatActions'
import { toast } from 'react-hot-toast'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import './style.css'


const Inquieries = () => {
    //other variables
    const loading = useSelector(state => state.inquiry.loading)
    const updating = useSelector(state => state.inquiry.updating)
    const deleting = useSelector(state => state.inquiry.deleting)
    const mode = useSelector(state => state.general.mode)
    const authenticated = useSelector(state => state.auth.authenticated)
    const inquiries = useSelector(state => state.inquiry.inquiries)
    const [inqArr, setInqArr] = useState([]);
    const theme = useTheme()
    const dispatch = useDispatch()
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [inqDetails, setInqDetails] = useState(undefined);
    const messagesEndRef = useRef(null)

    useEffect(() => {
        if (loading === true) {
            toast.loading('Processing...', {
                id: 'loading'
            })
        }
        else if (loading === false) {
            toast.dismiss('loading')
        }

    }, [loading]);

    useEffect(() => {
        if (deleting === true) {
            toast.loading('Deleting...', {
                id: 'Deleting'
            })
        }
        else if (deleting === false) {
            toast.dismiss('Deleting')
        }

    }, [deleting]);

    useEffect(() => {
        if (updating === true) {
            toast.loading('Updating...', {
                id: 'Updating'
            })
        }
        else if (updating === false) {
            toast.dismiss('Updating')
        }

    }, [updating]);

    //Inquiry Chat
    const chatSending = useSelector(state => state.inquiry.chatSending)
    const chatLoading = useSelector(state => state.inquiry.chatLoading)
    const chatDeleting = useSelector(state => state.inquiry.chatDeleting)
    const chats = useSelector(state => state.inquiry.chats)
    const [Sender, setSender] = useState('Admin');
    const [inquiryID, setInquiryID] = useState(undefined);
    const [showChat, setShowChat] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [delChatShow, setDelChatShow] = useState(false);
    const [Msg, setMsg] = useState(undefined);

    useEffect(() => {
        if (chatDeleting === true) {
            toast.loading('Deleting...', {
                id: 'chatDeleting'
            })
        }
        else if (chatDeleting === false) {
            toast.dismiss('chatDeleting')
        }

    }, [chatDeleting]);

    useEffect(() => {
        if (chatSending === true) {
            toast.loading('Sending...', {
                id: 'sending'
            })
        }
        else if (chatSending === false) {
            toast.dismiss('sending')
        }

    }, [chatSending]);

    useEffect(() => {
        scrollToBottom()
    }, [chats]);

    const showChatModal = (inquiry) => {

        const form = {
            InqID: inquiry.InqID
        }
        dispatch(getAllChats(form))
        setShowChat(true)
        setInquiryID(inquiry.InqID)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const sendMsg = (e) => {
        if (message === '' || message === undefined) {
            toast.error("Please enter a message", {
                id: 'noMsg'
            })
        }
        else {
            const form = {
                Msg: message,
                Sender,
                InqID: inquiryID
            }
            setMessage("")
            dispatch(submitChat(form))
        }

    }

    const chatClose = () => {
        setMessage('')
        setShowChat(false)
        dispatch(clearInqChat())
    }

    const showDelChatModal = (msg) => {
        setDelChatShow(true)
        setMsg(msg)
    }

    const delMsgClose = () => {
        setDelChatShow(false)
        setMsg(undefined)
    }

    const deleteMsg = () => {
        const form = {
            MsgID: Msg.MsgID,
            InqID: Msg.InqID
        }
        dispatch(deleteChatMsg(form))
        setDelChatShow(false)
        setMsg(undefined)
    }

    const renderDelChatMsg = () => {
        if (!Msg) {
            return null
        }
        return (
            <NewModel ModalTitle='Confirm Action'
                show={delChatShow}
                handleClose={deleteMsg}
                close={delMsgClose}
                buttons={[
                    {
                        label: 'No',
                        color: 'secondary',
                        onClick: delMsgClose
                    },
                    {
                        label: 'Yes',
                        color: 'danger',
                        onClick: deleteMsg
                    }
                ]}
            >
                <Typography sx={{ color: theme.palette.primary[600], fontWeight: 'bold', fontSize: '1rem' }}>
                    Are you sure you want to delete following message
                </Typography>
                <Typography sx={{ color: theme.palette.primary[300] }}>
                    {`Message ID : ${Msg.MsgID}`}
                </Typography>
                <Typography sx={{ color: theme.palette.primary[300] }}>
                    {`Message : ${Msg.Msg}`}
                </Typography>
            </NewModel>
        )
    }

    const renderInqChat = () => {
        return (
            <NewModel
                show={showChat}
                handleClose={chatClose}
                close={chatClose}
                size='md'
                ModalTitle=' Inquiry Chat'
                buttons={[
                    {
                        type: 'submit',
                        label: 'Send',
                        color: 'primary',
                        onClick: sendMsg
                    }
                ]}>
                <Typography sx={{ color: theme.palette.primary[600] }}>
                    <div style={{ display: 'flex', margin: '1rem', overflowY: 'scroll', height: '50vh', position: 'relative', flexDirection: 'column' }}>
                        {
                            chatLoading === false ?
                                chats.length > 0 ?
                                    chats.map((chat, index) => (
                                        chat.Sender === 'Customer' ?
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '.5rem' }} key={index}>
                                                <div style={{ maxWidth: '20rem', display: 'flex', height: 'auto', alignItems: 'center' }}>
                                                    {/* <FaRegUserCircle style={{ fontSize: '2rem' }} /> */}
                                                    <div style={{ marginLeft: '.5rem', alignContent: 'baseline', lineHeight: '2rem', position: 'relative', maxWidth: '100%', borderRadius: '20px', backgroundColor: '#f7ad00', padding: '.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                                                        {chat.Msg}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '.5rem' }} key={index}>
                                                <div style={{ maxWidth: '20rem', display: 'flex', height: 'auto', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', marginRight: '.5rem', alignContent: 'baseline', lineHeight: '2rem', position: 'relative', maxWidth: '100%', borderRadius: '20px', backgroundColor: '#f7ad00', padding: '.5rem', paddingLeft: '1rem' }}>
                                                        {chat.Msg}

                                                    </div>
                                                    <div>
                                                        <BsThreeDotsVertical onClick={() => showDelChatModal(chat)}/>
                                                    </div>
                                                </div>
                                            </div>
                                    )) :
                                    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '1.5rem' }}>
                                            No Messages
                                        </Typography>
                                    </div>
                                :
                                <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#333"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>

                        }
                        <div ref={messagesEndRef} ></div>
                    </div>

                </Typography>

                <Row style={{ marginTop: '1rem' }}>
                    <Col md={12}>
                        <Input
                            value={message}
                            placeholder={'Message...'}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    sendMsg()
                                }
                            }}
                        />
                    </Col>
                </Row>

            </NewModel>
        )
    }

    //Inqury detail update
    const [InqID, setInqID] = useState(undefined);
    const [AssignPerson, setAssignPerson] = useState(undefined);
    const [AssignContactNo, setAssignContactNo] = useState(undefined);
    const [CompanyNote, setCompanyNote] = useState(undefined);

    // Inquiry Update
    const [upInqModal, setUpInqModal] = useState(false);

    const updateShow = (inquiry) => {
        setInqID(inquiry.InqID)
        setAssignPerson(inquiry.AssignPerson)
        setAssignContactNo(inquiry.AssignContactNo)
        setCompanyNote(inquiry.CompanyNote)

        setUpInqModal(true)
    }
    const upClose = () => {
        setInqID(undefined)
        setAssignContactNo(undefined)
        setAssignPerson(undefined)
        setCompanyNote(undefined)
        setUpInqModal(false)
    }

    const handleUpdate = () => {
        const form = {
            InqID,
            AssignPerson,
            AssignContactNo,
            CompanyNote
        }
        dispatch(updateInquiry(form))
        setInqID(undefined)
        setAssignContactNo(undefined)
        setAssignPerson(undefined)
        setCompanyNote(undefined)
        setUpInqModal(false)
    }
    const renderUpdateInqModal = () => {
        return (
            <NewModel
                show={upInqModal}
                close={upClose}
                handleClose={handleUpdate}
                ModalTitle="Update Inquiry Details"
                size='lg'>
                <Typography sx={{ color: theme.palette.primary[600] }}>
                    <Row>
                        <Col md={6}>
                            <label className='key' >Assign Person</label>
                            <Input
                                value={AssignPerson}
                                placeholder={'Assign Person'}
                                onChange={(e) => setAssignPerson(e.target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <label className='key' >Assign Contact No</label>
                            <Input
                                value={AssignContactNo}
                                placeholder={'Assign Contact No'}
                                onChange={(e) => setAssignContactNo(e.target.value)}
                            />
                        </Col>
                        <Col md={12}>
                            <label className='key' >Company Note</label>
                            <textarea
                                style={{ marginBottom: '.5rem'}}   
                                className='form-control' 
                                value={CompanyNote}
                                placeholder={'Company Note'}
                                onChange={(e) => setCompanyNote(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Typography>
            </NewModel>
        )
    }

    //Inquiry Status update
    const inqStatusUpdate = (state, inquiry) => {
        const form = {
            InqID: inquiry.InqID,
            Status: state,
            businessEmail: inquiry.BusinessEmail
        }
        dispatch(updateInqStatus(form))
    }

    //Inquiry Details Modal
    const [showInqDeteModal, setShowInqDeteModal] = useState(false);
    const [showEnlarged, setShowEnlarged] = useState(false);
    const [enlargedPic, setEnlargedPic] = useState(undefined);
    const showEnlargedPic = (img) => {
        setShowEnlarged(true)
        setEnlargedPic(img)
        setShowInqDeteModal(false)
    }
    const enlarge = () => {
        return (
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: .75, ease: 'easeInOut' }}
                initial={{ opacity: 0 }}
            >
                {
                    showEnlarged ?
                        <div style={{  top: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '-10' }}>
                            <img className='enlargedImage' src={`${enlargedPic}`} alt='img' onClick={() => {
                                setShowEnlarged(false)
                                setEnlargedPic(undefined)
                            }} />
                        </div>
                        :
                        null
                }
            </motion.div>
        )
    }

    const inqDeteModalShow = (inquiry) => {
        setInqDetails(inquiry)
        setShowInqDeteModal(true)
    }

    const deteClose = () => {
        setInqDetails(undefined)
        setShowInqDeteModal(false)
    }

    const inquiryDetails = () => {
        if (!inqDetails) {
            return null
        }
        return (
            <NewModel
                show={showInqDeteModal}
                handleClose={deteClose}
                close={deteClose}
                size='lg'
                ModalTitle='Inquiry Details'
                buttons={[
                    {
                        label: 'Close',
                        color: 'secondary',
                        onClick: deteClose
                    }
                ]}>

                <Row>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Inquiry ID</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'> {inqDetails.InqID} </p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Model No</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'> {inqDetails.ModelNo} </p>
                        </Typography>

                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Serial Number</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'> {inqDetails.SerialNo} </p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Service Type</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.ServiceType}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Brand</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.Brand}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Person Name</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.PersonName}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Contact Number</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'> {inqDetails.ContactNo} </p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Note</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.Note}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Status</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.Status}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Assigned Person</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.AssignPerson}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Assigned Person Contact</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.AssignContactNo}</p>
                        </Typography>
                    </Col>
                    <Col md={6}>
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key' >Company Note</label>
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary[300] }}>
                            <p className='value'>{inqDetails.CompanyNote}</p>
                        </Typography>
                    </Col>
                    <Col >
                        <Typography sx={{ color: theme.palette.primary[600] }}>
                            <label className='key'>Inquiry Pictures</label>
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            {
                                inqDetails.Image === "N/A" ?
                                    <Typography sx={{ color: theme.palette.primary[300] }}>
                                        <p>No Image</p>
                                    </Typography>
                                    :
                                    <div key={inqDetails.Image} className='proImg'>
                                        <LazyLoadImage
                                            src={`https://d8qrg89hf8e72.cloudfront.net/${inqDetails.Image}`}
                                            alt='inquiry-image'
                                            onClick={() => showEnlargedPic(`https://d8qrg89hf8e72.cloudfront.net/${inqDetails.Image}`)}
                                        />
                                    </div>
                            }

                        </div>
                    </Col>
                </Row>
            </NewModel>
        )
    }

    //Inquiry Delete
    const [delInqModal, setDelInqModal] = useState(false);

    const deleteInqShow = (inquiry) => {
        setInqDetails(inquiry)
        setDelInqModal(true)
    }

    const delClose = () => {
        setInqDetails(undefined)
        setDelInqModal(false)
    }

    const deleteInquiry = () => {
        dispatch(deleteInquiries(inqDetails))
        setInqDetails(undefined)
        setDelInqModal(false)
    }

    const delInquiry = () => {
        if (!inqDetails) {
            return null
        }
        return (
            <NewModel ModalTitle='Confirm Action'
                show={delInqModal}
                handleClose={deleteInquiry}
                close={delClose}
                buttons={[
                    {
                        label: 'No',
                        color: 'secondary',
                        onClick: delClose
                    },
                    {
                        label: 'Yes',
                        color: 'danger',
                        onClick: deleteInquiry
                    }
                ]}
            >
                <Typography sx={{ color: theme.palette.primary[600], fontWeight: 'bold', fontSize: '1rem' }}>
                    Are you sure you want to delete following inquiry
                </Typography>
                <Typography sx={{ color: theme.palette.primary[300] }}>
                    {`Inquiry ID : ${inqDetails.InqID}`}
                </Typography>
                <Typography sx={{ color: theme.palette.primary[300] }}>
                    {`Model No : ${inqDetails.ModelNo}`}
                </Typography>
            </NewModel>
        )
    }

    // Search
    const handleChange = (e, v) => {
        setPage(v)
    }

    useEffect(() => {
        if (searchText !== '') {
            const resultsArray = inquiries.filter(inquiry =>
                inquiry.InqID.toLowerCase().includes(searchText) ||
                inquiry.ModelNo.toLowerCase().includes(searchText) ||
                inquiry.SerialNo.toLowerCase().includes(searchText) ||
                inquiry.ServiceType.toLowerCase().includes(searchText) ||
                inquiry.Status.toLowerCase().includes(searchText)
            )
            setInqArr(resultsArray.slice(0, 9))
        }
        if (searchText === '' && searchText.length === 0) {
            setInqArr(inquiries)
        }
    }, [searchText])

    useEffect(() => {
        dispatch(getAllInquiries())
    }, [mode]);

    useEffect(() => {
        if (page === 1) {
            if (inquiries.length < 11) {
                const inquiryArr = inquiries.slice(0, inquiries.length)
                setInqArr(inquiryArr)
            }
            else {
                const inquiryArr = inquiries.slice(0, 10)
                setInqArr(inquiryArr)
            }

        }
        else {
            if ((page - 1) * 10 + 10 > inquiries.length) {
                const inquiryArr = inquiries.slice((page - 1) * 10, inquiries.length)
                setInqArr(inquiryArr)
            }
            {
                const inquiryArr = inquiries.slice((page - 1) * 10, (page - 1) * 10 + 10)
                setInqArr(inquiryArr)
            }

        }
    }, [page, inquiries]);

    if (!authenticated) {
        return <Navigate to='/' />
    }

    const renderInquiryTable = () => {
        return (
            <>
                <Table striped style={{ fontSize: 14, alignItems: '' }} responsive="lg">
                    <thead>
                        <tr>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    #
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Inquiry ID
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Model No
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Serial No
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Service Type
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Brand
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Person Name
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Status
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Actions
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inqArr.length > 0 ?
                                inqArr.map((inquiry, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {index + 1}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {inquiry.InqID}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {inquiry.ModelNo}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {inquiry.SerialNo}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {inquiry.ServiceType}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {inquiry.Brand}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {inquiry.PersonName}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                <select
                                                    style={{ width: 'auto' }}
                                                    className='form-control'
                                                    value={inquiry.Status}
                                                    onChange={(e) => { inqStatusUpdate(e.target.value, inquiry) }}
                                                >
                                                    <option value="Resolved" >Resolved</option>
                                                    <option value="Pending" >Pending</option>
                                                    <option value="Closed" >Closed</option>
                                                </select>
                                            </Typography>
                                        </td>
                                        <td>
                                            {
                                                <div style={{ display: 'flex' }}>
                                                    <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                        <IconButton onClick={() => inqDeteModalShow(inquiry)} >
                                                            <MdRemoveRedEye style={{ color: theme.palette.secondary[300] }} />
                                                        </IconButton>
                                                    </Typography>
                                                    <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                        <IconButton onClick={() => showChatModal(inquiry)} >
                                                            <MdChat style={{ color: theme.palette.secondary[300] }} />
                                                        </IconButton>
                                                    </Typography>
                                                    <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                        <IconButton onClick={() => updateShow(inquiry)} >
                                                            <MdEdit style={{ color: theme.palette.secondary[300] }} />
                                                        </IconButton>
                                                    </Typography>
                                                    <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                        <IconButton onClick={() => deleteInqShow(inquiry)} >
                                                            <MdDelete style={{ color: theme.palette.secondary[300] }} />
                                                        </IconButton>
                                                    </Typography>

                                                </div>
                                            }
                                        </td>
                                    </tr>

                                )
                                :
                                null
                        }

                    </tbody>
                </Table>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }} >
                    <Stack spacing={2} >
                        <Pagination count={Math.ceil(inquiries.length / 10)} color="secondary" page={page} onChange={handleChange} />
                    </Stack>
                </div>
            </>
        )
    }
    return (
        <>
            {
                showEnlarged ? null :
                    <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: .75, ease: 'easeInOut' }}
                        initial={{ opacity: 0 }}
                    >
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '50px' }} >
                                        <Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
                                            INQUIERIES
                                        </Typography>


                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <div style={{ display: 'flex', height: '2rem', alignItems: 'center' }}>
                                            <IconButton onClick={() => dispatch(getAllInquiries())}>
                                                <MdRefresh />
                                            </IconButton>
                                            <Typography onClick={() => dispatch(getAllInquiries())} style={{ cursor: 'pointer' }} >Refresh</Typography>
                                        </div>
                                        <FlexBetween
                                            backgroundColor={theme.palette.background.alt}
                                            borderRadius="9px"
                                            gap="3px"
                                            padding="0.1rem 1.5rem"
                                        >
                                            <InputBase
                                                placeholder='Search...'
                                                onChange={(e) => setSearchText(e.target.value.toString())}
                                            />
                                            <IconButton>
                                                <Search />
                                            </IconButton>
                                        </FlexBetween>
                                    </div>


                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        loading ?
                                            <div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <ThreeDots
                                                    height="80"
                                                    width="80"
                                                    radius="9"
                                                    color={theme.palette.primary[200]}
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div> :
                                            inquiries.length > 0 ?
                                                <motion.div
                                                    whileInView={{ opacity: [0, 1] }}
                                                    transition={{ duration: .75, ease: 'easeInOut' }}
                                                    initial={{ opacity: 0 }}
                                                >
                                                    {renderInquiryTable()}
                                                </motion.div>

                                                :
                                                <div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
                                                        No Inquiries
                                                    </Typography>
                                                </div>
                                    }
                                </Col>
                            </Row>

                        </Container>

                    </motion.div>
            }

            {delInquiry()}
            {inquiryDetails()}
            {renderUpdateInqModal()}
            {renderInqChat()}
            {renderDelChatMsg()}
            {enlarge()}
        </>
    );
}

export default Inquieries;
