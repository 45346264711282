import { inquiryConsts, chatConsts } from "actions/constants";

const initState = {
    chatSending: false,
    chatLoading: false,
    chatDeleting: false,
    loading: false,
    updating: false,
    deleting: false,
    inquiries: [],
    chats: []
}

export default (state = initState, action) => {
    console.log(action)
    switch (action.type) {
        default:
            state = {
                ...initState
            }
            break

        case inquiryConsts.GET_ALL_INQUIRIES_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case inquiryConsts.GET_ALL_INQUIRIES_FAILED:
            state = {
                ...state,
                loading: false
            }
            break

        case inquiryConsts.GET_ALL_INQUIRIES_SUCCESS:
            state = {
                ...state,
                inquiries: action.payload,
                loading: false
            }
            break
        case inquiryConsts.INQUIRY_STATUS_UPDATE_REQUEST:
            state = {
                ...state,
                updating: true
            }
            break
        case inquiryConsts.INQUIRY_STATUS_UPDATE_SUCCESS:
            state = {
                ...state,
                inquiries: action.payload,
                updating: false
            }
            break
        case inquiryConsts.INQUIRY_STATUS_UPDATE_FAILED:
            state = {
                ...state,
                updating: false
            }
            break
        
        case inquiryConsts.INQUIRY_UPDATE_REQEUST:
            state = {
                ...state,
                updating: true
            }
            break
        case inquiryConsts.INQUIRY_UPDATE_FAILED:
            state = {
                ...state,
                updating: false
            }
            break
        case inquiryConsts.INQUIRY_UPDATE_SUCCESS:
            state = {
                ...state,
                inquiries: action.payload,
                updating: false
            }
            break
        case inquiryConsts.INQUIRY_DELETE_REQUEST:
            state = {
                ...state,
                deleting: true
            }
            break
        case inquiryConsts.INQUIRY_DELETE_SUCCESS:
            state = {
                ...state,
                inquiries: action.payload,
                deleting: false
            }
            break
        case inquiryConsts.INQUIRY_DELETE_FAILED:
            state = {
                ...state,
                deleting: false
            }
            break
        case chatConsts.CHAT_SUBMIT_REQUEST:
            state = {
                ...state,
                chatSending: true
            }
            break

        case chatConsts.CHAT_SUBMIT_SUCCESS:
            state = {
                ...state,
                chatSending: false,
                chats: action.payload
            }
            break
        case chatConsts.CHAT_FETCH_SUCCESS:
            state = {
                ...state,
                chatLoading: false,
                chats: action.payload
            }
            break
        case chatConsts.CHAT_FETCH_REQUEST:
            state = {
                ...state,
                chatLoading: true
            }
            break
        case chatConsts.CHAT_FEED_CLEARED:
            state = {
                ...state,
                chats: []
            }
            break
        case chatConsts.CHAT_FETCH_REQUEST:
            state = {
                ...state,
                chatDeleting: true
            }
            break
        case chatConsts.CHAT_MSG_DELETE_REQUEST:
            state = {
                ...state,
                chatDeleting: true
            }
            break
        case chatConsts.CHAT_MSG_DELETE_SUCCESS:
            state = {
                ...state,
                chatDeleting: false,
                chats: action.payload
            }
            break
        case chatConsts.CHAT_MSG_DELETE_FAILED:
            state = {
                ...state,
                chatDeleting: false
            }
            break
    }

    return state
}