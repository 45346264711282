import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NewModel } from 'components/Modal'
import { Input } from 'components/input/input'
import { Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTheme, IconButton, InputBase } from '@mui/material'
import FlexBetween from 'components/FlexBetween'
import { Search } from '@mui/icons-material';
import { useNavigate, Navigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { MdRemoveRedEye, MdDelete, MdEdit, MdRefresh } from 'react-icons/md'
import { addCustomer, getAllCustomers, removeCustomer, updateCustomer, updateCustomerStatus } from 'actions/customerActions'
import { toast } from 'react-hot-toast'
import { motion } from 'framer-motion'

const Customers = () => {

	const loading = useSelector(state => state.customer.loading)
	const deleting = useSelector(state => state.customer.deleting)
	const updating = useSelector(state => state.customer.updating)
	const adding = useSelector(state => state.customer.adding)

	useEffect(() => {
		if (adding === true) {
			toast.loading('Processing...', {
				id: 'adding'
			})
		}
		else if (adding === false) {
			toast.dismiss('adding')
		}

	}, [adding]);

	useEffect(() => {
		if (updating === true) {
			toast.loading('Updating...', {
				id: 'Updating'
			})
		}
		else if (updating === false) {
			toast.dismiss('Updating')
		}

	}, [updating]);

	useEffect(() => {
		if (deleting === true) {
			toast.loading('Deleting...', {
				id: 'Deleting'
			})
		}
		else if (deleting === false) {
			toast.dismiss('Deleting')
		}

	}, [deleting]);

	useEffect(() => {
		if (loading === true) {
			toast.loading('Processing...', {
				id: 'loading'
			})
		}
		else if (loading === false) {
			toast.dismiss('loading')
		}

	}, [loading]);

	//Form Data
	const [companyEmail, setCompanyEmail] = useState(undefined);
	const [companyName, setCompanyName] = useState(undefined);
	const [companyContactNo, setCompanyContactNo] = useState(undefined);
	const [customerID, setCustomerID] = useState(undefined);

	//Other variables
	const navigate = useNavigate()
	const mode = useSelector(state => state.general.mode)
	const authenticated = useSelector(state => state.auth.authenticated)
	const customers = useSelector(state => state.customer.customers)
	const [custArr, setCustArr] = useState([]);
	const theme = useTheme()
	const [show, setShow] = useState(false);
	const dispatch = useDispatch()
	const handleShow = () => setShow(true);
	const [page, setPage] = useState(1);
	const [custDetails, setCustDetails] = useState(undefined);
	const [delCustModal, setDelCustModal] = useState(false);
	const [upCustModal, setUpCustModal] = useState(false);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		if (searchText !== '') {
			const resultsArray = customers.filter(customer =>
				customer.companyName.toLowerCase().includes(searchText)
			)
			setCustArr(resultsArray.slice(0, 9))
		}
		if (searchText === '' && searchText.length === 0) {
			setCustArr(customers)
		}
	}, [searchText])

	useEffect(() => {
		dispatch(getAllCustomers())
	}, [mode]);

	useEffect(() => {
		if (page === 1) {
			if (customers.length < 11) {
				const customerArray = customers.slice(0, customers.length)
				setCustArr(customerArray)
			}
			else {
				const customerArray = customers.slice(0, 10)
				setCustArr(customerArray)
			}

		}
		else {
			if ((page - 1) * 10 + 10 > customers.length) {
				const customerArray = customers.slice((page - 1) * 10, customers.length)
				setCustArr(customerArray)
			}
			{
				const customerArray = customers.slice((page - 1) * 10, (page - 1) * 10 + 10)
				setCustArr(customerArray)
			}

		}
	}, [page, customers]);

	const close = () => {
		setCompanyEmail(undefined)
		setCompanyName(undefined)
		setCompanyContactNo(undefined)
		setShow(false)
	}

	const handleClose = () => {
		const form = {
			companyName,
			companyContactNo,
			companyEmail
		}

		dispatch(addCustomer(form))
		setCompanyEmail(undefined)
		setCompanyName(undefined)
		setCompanyContactNo(undefined)
		setShow(false)
	}

	const renderAddCustomers = () => {
		return (
			<NewModel
				show={show}
				close={close}
				handleClose={handleClose}
				ModalTitle="Add New Customer">
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="Company Name"
						value={companyName}
						placeholder={'Company Name'}
						onChange={(e) => setCompanyName(e.target.value)}
					/>
				</Typography>
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="Company Email"
						value={companyEmail}
						placeholder={'Company Email'}
						onChange={(e) => setCompanyEmail(e.target.value)}
					/>
				</Typography>
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="Company Contact No"
						value={companyContactNo}
						placeholder={'Company Contact Number'}
						onChange={(e) => setCompanyContactNo(e.target.value)}
					/>
				</Typography>
			</NewModel>
		)
	}

	// Customer Status Update
	const customerStatusUpdate = (state, customer) => {
        const form = {
            CID: customer.CID,
            Status: state
        }
        dispatch(updateCustomerStatus(form))
    }

	const renderCustomerTable = () => {
		return (
			<>
				<Table style={{ fontSize: 14, alignItems: '' }} striped responsive="lg">
					<thead>
						<tr>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									#
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Customer ID
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Company Name
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Company Email
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Company Contact
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Status
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Actions
								</Typography>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							custArr.length > 0 ?
								custArr.map((customer, index) =>
									<tr key={index}>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{index + 1}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{customer.CID}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{customer.companyName}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{customer.companyEmail}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{customer.companyContactNo}
											</Typography>
										</td>
										<td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                <select
                                                    style={{ width: 'auto' }}
                                                    className='form-control'
                                                    value={customer.Status}
                                                    onChange={(e) => { customerStatusUpdate(e.target.value, customer) }}
                                                >
                                                    <option value="Disabled" >Disabled</option>
                                                    <option value="Enabled" >Enabled</option>
                                                </select>
                                            </Typography>
                                        </td>
										<td>
											{
												<div style={{ display: 'flex' }}>
													<Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
														<IconButton onClick={() => {navigate(`/cust-details/${customer.CID}`)}} >
															<MdRemoveRedEye style={{ color: theme.palette.secondary[300] }} />
														</IconButton>
													</Typography>
													<Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
														<IconButton onClick={() => updateShow(customer)} >
															<MdEdit style={{ color: theme.palette.secondary[300] }} />
														</IconButton>
													</Typography>
													<Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
														<IconButton onClick={() => delCustShow(customer)} >
															<MdDelete style={{ color: theme.palette.secondary[300] }} />
														</IconButton>
													</Typography>

												</div>
											}
										</td>
									</tr>

								)
								: null
						}

					</tbody>
				</Table>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }} >
					<Stack spacing={2} style={{}} >
						<Pagination count={Math.ceil(customers.length / 10)} color="secondary" page={page} onChange={handleChange} />
					</Stack>
				</div>
			</>
		)
	}

	const handleChange = (e, v) => {
		setPage(v)
	}

	const deleteCustomer = () => {
		setDelCustModal(false)
		setCustDetails(undefined)
		dispatch(removeCustomer(custDetails.CID))
	}

	const delClose = () => {
		setCustDetails(undefined)
		setDelCustModal(false)
	}

	const delCustShow = (customer) => {
		setCustDetails(customer)
		setDelCustModal(true)
	}

	const delCustomer = () => {
		if (!custDetails) {
			return null
		}
		return (
			<NewModel ModalTitle='Confirm Action'
				show={delCustModal}
				handleClose={deleteCustomer}
				close={delClose}
				buttons={[
					{
						label: 'No',
						color: 'secondary',
						onClick: delClose
					},
					{
						label: 'Yes',
						color: 'danger',
						onClick: deleteCustomer
					}
				]}
			>
				<Typography sx={{ color: theme.palette.primary[600], fontWeight: 'bold', fontSize: '1rem' }}>
					Are you sure you want to delete
				</Typography>
				<Typography sx={{ color: theme.palette.primary[300] }}>
					{custDetails.companyName}
				</Typography>
			</NewModel>
		)
	}

	const updateShow = (customer) => {
		setCustomerID(customer.CID)
		setCompanyName(customer.companyName)
		setCompanyEmail(customer.companyEmail)
		setCompanyContactNo(customer.companyContactNo)

		setUpCustModal(true)
	}

	const upClose = () => {
		setUpCustModal(false)
		setCustDetails(undefined)
	}

	const handleUpdate = () => {
		const form = {
			'CID': customerID,
			companyName,
			companyContactNo,
			companyEmail
		}

		setUpCustModal(false)
		setCustDetails(undefined)
		dispatch(updateCustomer(form))
		setCompanyEmail(undefined)
		setCompanyName(undefined)
		setCompanyContactNo(undefined)
	}

	const renderUpdateCustomerModal = () => {
		return (
			<NewModel
				show={upCustModal}
				close={upClose}
				handleClose={handleUpdate}
				ModalTitle="Update Customer Details"
				size='lg'>
				<Typography sx={{ color: theme.palette.primary[600] }}>
					<Row>
						<Col md={6}>
							<label className='key' >Company Name</label>
							<Input
								value={companyName}
								placeholder={'Company Name'}
								onChange={(e) => setCompanyName(e.target.value)}
							/>
						</Col>
						<Col md={6}>
							<label className='key' >Company Email</label>
							<Input
								value={companyEmail}
								placeholder={'Company Email'}
								onChange={(e) => setCompanyEmail(e.target.value)}
							/>
						</Col>
						<Col md={6}>
							<label className='key' >Company Contact</label>
							<Input
								value={companyContactNo}
								placeholder={'Company Contact'}
								onChange={(e) => setCompanyContactNo(e.target.value)}
							/>
						</Col>
					</Row>
				</Typography>
			</NewModel>
		)
	}

	if (!authenticated) {
		return <Navigate to='/' />
	}
	return (
		<motion.div
			whileInView={{ opacity: [0, 1] }}
			transition={{ duration: .75, ease: 'easeInOut' }}
			initial={{ opacity: 0 }}
		>
			<Container>
				<Row>
					<Col md={12}>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '50px' }} >
							<Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
								CUSTOMERS
							</Typography>

							<button className='form-control' style={{ width: 'auto', margin: '0 10px' }} onClick={handleShow}>Add New Customer</button>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
							<div style={{ display: 'flex', height: '2rem', alignItems: 'center' }}>
								<IconButton onClick={() => dispatch(getAllCustomers())}>
									<MdRefresh />
								</IconButton>
								<Typography onClick={() => dispatch(getAllCustomers())} style={{ cursor: 'pointer' }} >Refresh</Typography>
							</div>
							<FlexBetween
								backgroundColor={theme.palette.background.alt}
								borderRadius="9px"
								gap="3px"
								padding="0.1rem 1.5rem"
							>
								<InputBase
									placeholder='Search...'
									onChange={(e) => setSearchText(e.target.value.toString())}
								/>
								<IconButton>
									<Search />
								</IconButton>
							</FlexBetween>
						</div>

					</Col>
				</Row>
				<Row>
					<Col>
						{
							loading ?
								<div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<ThreeDots
										height="80"
										width="80"
										radius="9"
										color={theme.palette.primary[200]}
										ariaLabel="three-dots-loading"
										wrapperStyle={{}}
										wrapperClassName=""
										visible={true}
									/>
								</div>
								:
								customers.length > 0 ?
									<motion.div
										whileInView={{ opacity: [0, 1] }}
										transition={{ duration: .75, ease: 'easeInOut' }}
										initial={{ opacity: 0 }}
									>
										{renderCustomerTable()}
									</motion.div>
									
						:
						<div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
								No Customers Found
							</Typography>
						</div>
						}
					</Col>
				</Row>

			</Container>
			{renderAddCustomers()}
			{delCustomer()}
			{renderUpdateCustomerModal()}
		</motion.div>
	);
}

export default Customers;
