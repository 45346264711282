import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NewModel } from 'components/Modal'
import { Input } from 'components/input/input'
import { useTheme, IconButton, InputBase, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate } from 'react-router-dom'
import FlexBetween from 'components/FlexBetween'
import { Search } from '@mui/icons-material';
import { ThreeDots } from 'react-loader-spinner'
import { MdDelete, MdRemoveRedEye, MdEdit, MdRefresh } from 'react-icons/md'
import { toast } from 'react-hot-toast'
import { addOrder, deleteOrder, getOrders, updateOrder } from 'actions/orderActions'
import { motion } from 'framer-motion'

const Orders = () => {
	// Other variables
	const orders = useSelector(state => state.order.orders)
	const loading = useSelector(state => state.order.loading)
	const deleting = useSelector(state => state.order.deleting)
	const updating = useSelector(state => state.order.updating)
	const adding = useSelector(state => state.order.adding)
	const mode = useSelector(state => state.general.mode)
	const authenticated = useSelector(state => state.auth.authenticated)
	const theme = useTheme()
	const dispatch = useDispatch()

	// Toasts
	useEffect(() => {
		if (adding === true) {
			toast.loading('Processing...', {
				id: 'loading'
			})
		}
		else if (adding === false) {
			toast.dismiss('loading')
		}

	}, [adding]);

	useEffect(() => {
		if (loading === true) {
			toast.loading('Processing...', {
				id: 'loading'
			})
		}
		else if (loading === false) {
			toast.dismiss('loading')
		}

	}, [loading]);

	useEffect(() => {
		if (updating === true) {
			toast.loading('Updating...', {
				id: 'Updating'
			})
		}
		else if (updating === false) {
			toast.dismiss('Updating')
		}

	}, [updating]);

	useEffect(() => {
		if (deleting === true) {
			toast.loading('Deleting...', {
				id: 'Deleting'
			})
		}
		else if (deleting === false) {
			toast.dismiss('Deleting')
		}

	}, [deleting]);

	// Handle Theme Change
	useEffect(() => {
		dispatch(getOrders())
	}, [mode]);

	// Handle Pages
	const [page, setPage] = useState(1);
	const handleChange = (e, v) => {
		setPage(v)
	}

	const [orderArr, setOrderArr] = useState([]);
	useEffect(() => {
		if (page === 1) {
			if (orders.length < 11) {
				const orArr = orders.slice(0, orders.length)
				setOrderArr(orArr)
			}
			else {
				const orArr = orders.slice(0, 10)
				setOrderArr(orArr)
			}

		}
		else {
			if ((page - 1) * 10 + 10 > orders.length) {
				const orArr = orders.slice((page - 1) * 10, orders.length)
				setOrderArr(orArr)
			}
			{
				const orArr = orders.slice((page - 1) * 10, (page - 1) * 10 + 10)
				setOrderArr(orArr)
			}

		}
	}, [page, orders]);

	// Handle Search
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		if (searchText !== '') {
			const resultsArray = orders.filter(order =>
				order.OID.toLowerCase().includes(searchText) ||
				order.PO_NUMBER.toLowerCase().includes(searchText) ||
				order.CUSTOMER.toLowerCase().includes(searchText) ||
				order.SUPPLIER.toLowerCase().includes(searchText) ||
				order.Status.toLowerCase().includes(searchText)
			)
			setOrderArr(resultsArray.slice(0, 9))
		}
		if (searchText === '' && searchText.length === 0) {
			setOrderArr(orders)
		}
	}, [searchText]);

	// Add Order
	// const [oid, setOid] = useState(undefined);
	const [po_date, setPo_date] = useState(undefined);
	const [po_number, setPo_number] = useState(undefined);
	const [customer, setCustomer] = useState(undefined);
	const [supplier, setsupplier] = useState(undefined);
	const [delivery_date, setDelivery_date] = useState(undefined);
	const [showAddModal, setShowAddModal] = useState(false);

	const closeAddModal = () => {
		setShowAddModal(false)
		// setOid(undefined)
		setPo_date(undefined)
		setPo_number(undefined)
		setCustomer(undefined)
		setsupplier(undefined)
		setDelivery_date(undefined)
	}

	const addNewOrder = () => {
		const form = {
			// oid,
			po_date,
			po_number,
			customer,
			supplier,
			delivery_date
		}
		dispatch(addOrder(form))
		setShowAddModal(false)
		// setOid(undefined)
		setPo_date(undefined)
		setPo_number(undefined)
		setCustomer(undefined)
		setsupplier(undefined)
		setDelivery_date(undefined)
	}

	const renderOrderAddModal = () => {
		return (
			<NewModel
				show={showAddModal}
				close={closeAddModal}
				handleClose={addNewOrder}
				ModalTitle="Add New Order">
				{/* <Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="Order ID"
						value={oid}
						placeholder={'Order ID'}
						onChange={(e) => setOid(e.target.value)}
					/>
				</Typography> */}
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="PO Number"
						value={po_number}
						placeholder={'PO Number'}
						onChange={(e) => setPo_number(e.target.value)}
					/>
				</Typography>
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						type='date'
						label="PO Date"
						value={po_date}
						placeholder={'PO Date'}
						onChange={(e) => setPo_date(e.target.value)}
					/>
				</Typography>

				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="Customer"
						value={customer}
						placeholder={'Customer'}
						onChange={(e) => setCustomer(e.target.value)}
					/>
				</Typography>
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						label="Supplier"
						value={supplier}
						placeholder={'Supplier'}
						onChange={(e) => setsupplier(e.target.value)}
					/>
				</Typography>
				<Typography sx={{ color: theme.palette.primary[500] }} >
					<Input
						type='date'
						label="Delivery Date"
						value={delivery_date}
						placeholder={'Delivery Date'}
						onChange={(e) => setDelivery_date(e.target.value)}
					/>
				</Typography>
			</NewModel>
		)
	}

	// Remove Order
	const [showOrderRemoveModal, setShowOrderRemoveModal] = useState(false);

	const showOrdRemModal = (order) => {
		setShowOrderRemoveModal(true)
		setPo_number(order.PO_NUMBER)
	}

	const closeRemoveModal = () => {
		setPo_number(undefined)
		setShowOrderRemoveModal(false)
	}

	const removeOrder = () => {
		const form = {
			po_number
		}
		dispatch(deleteOrder(form))
		setPo_number(undefined)
		setShowOrderRemoveModal(false)
	}

	const renderOrderRemoveModal = () => {
		return (
			<NewModel ModalTitle='Confirm Action'
				show={showOrderRemoveModal}
				handleClose={removeOrder}
				close={closeRemoveModal}
				buttons={[
					{
						label: 'No',
						color: 'secondary',
						onClick: closeRemoveModal
					},
					{
						label: 'Yes',
						color: 'danger',
						onClick: removeOrder
					}
				]}
			>
				<Typography sx={{ color: theme.palette.primary[600], fontWeight: 'bold', fontSize: '1rem' }}>
					Are you sure you want to delete following order
				</Typography>
				<Typography sx={{ color: theme.palette.primary[300] }}>
					{`PO Number : ${po_number}`}
				</Typography>
			</NewModel>
		)
	}

	// Order Update
	const [showOrderUpdateModal, setShowOrderUpdateModal] = useState(false);
	const [performance_bond, setPerformanceBond] = useState(undefined);
	const [agreement, setAgreement] = useState(undefined);
	const [po_to_supplier, setPoToSupplier] = useState(undefined);
	const [payment_status_tspl, setPaymentStatusTSPL] = useState(undefined);
	const [shipment_date, setShipmentDate] = useState(undefined);
	const [delivery_term, setDeliveryTerm] = useState(undefined);
	const [clearing_status, setClearingStatus] = useState(undefined);
	const [invoice_status, setInvoiceStatus] = useState(undefined);
	const [payment_status, setPaymentStatus] = useState(undefined);
	const [po_pdf, setPoPDF] = useState(undefined);
	const [bond_pdf, setBondPDF] = useState(undefined);

	// Handle File Upload
	const handlePoPDF = (e) => {
		setPoPDF(e.target.files[0])
	}

	const handleBondPDF = (e) => {
		setBondPDF(e.target.files[0])
	}

	const showOrdUpModal = (order) => {
		setShowOrderUpdateModal(true)
		// setOid(order.OID)
		setPo_date(order.PO_DATE)
		setPo_number(order.PO_NUMBER)
		setCustomer(order.CUSTOMER)
		setsupplier(order.SUPPLIER)
		setDelivery_date(order.DELEVERY_DATE)
		setPerformanceBond(order.PERFORMANCE_BOND)
		setAgreement(order.AGREEMENT)
		setPoToSupplier(order.PO_TO_SUPPLIER)
		setPaymentStatusTSPL(order.PAYMENT_STATUS_TSPL)
		setShipmentDate(order.SHIPMENT_DATE)
		setDeliveryTerm(order.DELIVERY_TERM)
		setClearingStatus(order.CLEARING_STATUS)
		setInvoiceStatus(order.INVOICE_STATUS)
		setPaymentStatus(order.PAYMENT_STATUS)
		setPoPDF(order.PO_PDF)
		setBondPDF(order.BOND_PDF)
	}

	const closeOrdUpModal = () => {
		setShowOrderUpdateModal(false)
		// setOid(undefined)
		setPo_date(undefined)
		setPo_number(undefined)
		setCustomer(undefined)
		setsupplier(undefined)
		setDelivery_date(undefined)
		setPerformanceBond(undefined)
		setAgreement(undefined)
		setPoToSupplier(undefined)
		setPaymentStatusTSPL(undefined)
		setShipmentDate(undefined)
		setDeliveryTerm(undefined)
		setClearingStatus(undefined)
		setInvoiceStatus(undefined)
		setPaymentStatus(undefined)
		setPoPDF(undefined)
		setBondPDF(undefined)
	}

	const orderUpdate = () => {
		const form = new FormData()
		// form.append('oid', oid)
		form.append('po_date', po_date)
		form.append('po_number', po_number)
		form.append('customer', customer)
		form.append('supplier', supplier)
		form.append('delivery_date', delivery_date)
		form.append('performance_bond', performance_bond)
		form.append('agreement', agreement)
		form.append('po_to_supplier', po_to_supplier)
		form.append('payment_status_tspl', payment_status_tspl)
		form.append('shipment_date', shipment_date)
		form.append('delivery_term', delivery_term)
		form.append('clearing_status', clearing_status)
		form.append('invoice_status', invoice_status)
		form.append('payment_status', payment_status)
		form.append('po_pdf', po_pdf)
		form.append('bond_pdf', bond_pdf)

		dispatch(updateOrder(form))
		setShowOrderUpdateModal(false)
		// setOid(undefined)
		setPo_date(undefined)
		setPo_number(undefined)
		setCustomer(undefined)
		setsupplier(undefined)
		setDelivery_date(undefined)
		setPerformanceBond(undefined)
		setAgreement(undefined)
		setPoToSupplier(undefined)
		setPaymentStatusTSPL(undefined)
		setShipmentDate(undefined)
		setDeliveryTerm(undefined)
		setClearingStatus(undefined)
		setInvoiceStatus(undefined)
		setPaymentStatus(undefined)
		setPoPDF(undefined)
		setBondPDF(undefined)
	}

	const renderUpdateOrdModal = () => {
		return (
			<NewModel
				show={showOrderUpdateModal}
				close={closeOrdUpModal}
				handleClose={orderUpdate}
				ModalTitle={`Update Order - ${po_number}`}
				size='xl'>
				<Row>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Details
					</Typography>

					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="PO Number"
								value={po_number}
								placeholder={'PO Number'}
								onChange={(e) => setPo_number(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								type='date'
								label="PO Date"
								value={po_date}
								placeholder={'PO Date'}
								onChange={(e) => setPo_date(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Customer"
								value={customer}
								placeholder={'Customer'}
								onChange={(e) => setCustomer(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Supplier"
								value={supplier}
								placeholder={'Supplier'}
								onChange={(e) => setsupplier(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								type='date'
								label="Delivery Date"
								value={delivery_date}
								placeholder={'Delivery Date'}
								onChange={(e) => setDelivery_date(e.target.value)}
							/>
						</Typography>
					</Col>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Order Processing Details
					</Typography>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Performance Bond Validity"
								value={performance_bond}
								placeholder={'Performance Bond'}
								onChange={(e) => setPerformanceBond(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Agreement"
								value={agreement}
								placeholder={'Agreement'}
								onChange={(e) => setAgreement(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="PO to Supplier"
								value={po_to_supplier}
								placeholder={'PO to Supplier'}
								onChange={(e) => setPoToSupplier(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' htmlFor='pstspl' >Payment Status TSPL</label>
							<select
								style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
								className='form-control'
								value={payment_status_tspl}
								onChange={(e) => setPaymentStatusTSPL(e.target.value)}
							>
								<option value="Pending" >Pending</option>
								<option value="Completed" >Completed</option>
							</select>
						</Typography>
					</Col>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Delivery And Payment Details
					</Typography>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								type='date'
								label="Shipment Date"
								value={shipment_date}
								placeholder={'Shipment Date'}
								onChange={(e) => setShipmentDate(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Delivery Term"
								value={delivery_term}
								placeholder={'Delivery Term'}
								onChange={(e) => setDeliveryTerm(e.target.value)}
							/>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' htmlFor='cs' >Clearing Status</label>
							<select
								style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
								className='form-control'
								value={clearing_status}
								onChange={(e) => setClearingStatus(e.target.value)}
							>
								<option value="Pending" >Pending</option>
								<option value="Completed" >Completed</option>
							</select>
						</Typography>
					</Col>

					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' htmlFor='is' >Invoice Status</label>
							<select
								style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
								className='form-control'
								value={invoice_status}
								onChange={(e) => setInvoiceStatus(e.target.value)}
							>
								<option value="Pending" >Pending</option>
								<option value="Completed" >Completed</option>
							</select>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' htmlFor='ps' >Payment Status</label>
							<select
								style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
								className='form-control'
								value={payment_status}
								onChange={(e) => setPaymentStatus(e.target.value)}
							>
								<option value="Pending" >Pending</option>
								<option value="Completed" >Completed</option>
							</select>
						</Typography>
					</Col>
					<Col md={12}>

					</Col>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Files
					</Typography>
					<Col md={6}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Po PDF"
								type='file'
								name='po_pdf'
								onChange={(e) => handlePoPDF(e)}
							/>
						</Typography>
					</Col>
					<Col md={6}>
						<Typography sx={{ color: theme.palette.primary[500] }} >
							<Input
								label="Bond PDF"
								type='file'
								name='bond_pdf'
								onChange={(e) => handleBondPDF(e)}
							/>
						</Typography>
					</Col>
				</Row>
			</NewModel>
		)
	}

	// Order Details
	const [showOrdDeteModal, setShowOrdDeteModal] = useState(false);

	const showOrderDetailsModal = (order) => {
		setShowOrdDeteModal(true)
		// setOid(order.OID)
		setPo_date(order.PO_DATE)
		setPo_number(order.PO_NUMBER)
		setCustomer(order.CUSTOMER)
		setsupplier(order.SUPPLIER)
		setDelivery_date(order.DELEVERY_DATE)
		setPerformanceBond(order.PERFORMANCE_BOND)
		setAgreement(order.AGREEMENT)
		setPoToSupplier(order.PO_TO_SUPPLIER)
		setPaymentStatusTSPL(order.PAYMENT_STATUS_TSPL)
		setShipmentDate(order.SHIPMENT_DATE)
		setDeliveryTerm(order.DELIVERY_TERM)
		setClearingStatus(order.CLEARING_STATUS)
		setInvoiceStatus(order.INVOICE_STATUS)
		setPaymentStatus(order.PAYMENT_STATUS)
		setPoPDF(order.PO_PDF)
		setBondPDF(order.BOND_PDF)
	}

	const closeOrdDeteModal = () => {
		setShowOrdDeteModal(false)
		// setOid(undefined)
		setPo_date(undefined)
		setPo_number(undefined)
		setCustomer(undefined)
		setsupplier(undefined)
		setDelivery_date(undefined)
		setPerformanceBond(undefined)
		setAgreement(undefined)
		setPoToSupplier(undefined)
		setPaymentStatusTSPL(undefined)
		setShipmentDate(undefined)
		setDeliveryTerm(undefined)
		setClearingStatus(undefined)
		setInvoiceStatus(undefined)
		setPaymentStatus(undefined)
		setPoPDF(undefined)
		setBondPDF(undefined)
	}

	const renderOrderDetailModal = () => {
		return (
			<NewModel
				show={showOrdDeteModal}
				close={closeOrdDeteModal}
				ModalTitle={`Order Details - ${po_number}`}
				size='xl'
				buttons={[
					{
						label: 'Close',
						color: 'secondary',
						onClick: closeOrdDeteModal
					}
				]}>
				<Row>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						PO Details
					</Typography>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }} >PO Number</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{po_number}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>PO Date</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{po_date}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }} >Customer</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{customer}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }} >Supplier</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{supplier}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Delivery Date</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{delivery_date}</p>
						</Typography>
					</Col>

					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Order Procesing Details
					</Typography>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Performance Bond Validity</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{performance_bond}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Agreement</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{agreement}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>PO To Supplier</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{po_to_supplier}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }} >Payment Status TSPL</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{payment_status_tspl}</p>
						</Typography>
					</Col>


					<Col md={12}>

					</Col>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Delivery And Payment Details
					</Typography>

					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Shipment Date</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{shipment_date}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Delivery Term</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{delivery_term}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Clearing Status</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{clearing_status}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Invoice Status</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{invoice_status}</p>
						</Typography>
					</Col>
					<Col md={3}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Payment Status</label>
						</Typography>
						<Typography sx={{ color: theme.palette.primary[300] }}>
							<p className='value'>{payment_status}</p>
						</Typography>
					</Col>
					<Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[600], marginBottom: '.5rem' }}>
						Files
					</Typography>
					<Col md={6}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>PO PDF</label>
						</Typography>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography sx={{ color: theme.palette.primary[300] }}>
								<p className='value'>{po_pdf}</p>
							</Typography>
							{
								po_pdf != 'N/A' ?
									<button className='form-control' style={{ width: 'auto', margin: '0 10px' }}
										onClick={() => window.open(`https://d8qrg89hf8e72.cloudfront.net/${po_pdf}`)}
									>Download PO PDF</button>
									: null
							}
						</div>
					</Col>
					<Col md={6}>
						<Typography sx={{ color: theme.palette.primary[600] }}>
							<label className='key' style={{ fontWeight: 'bold' }}>Bond PDF</label>
						</Typography>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography sx={{ color: theme.palette.primary[300] }}>
								<p className='value'>{bond_pdf}</p>
							</Typography>
							{
								bond_pdf != 'N/A' ?
									<button className='form-control' style={{ width: 'auto', margin: '0 10px' }}
										onClick={() => window.open(`https://d8qrg89hf8e72.cloudfront.net/${bond_pdf}`)}
									>Download Bond PDF</button>
									: null
							}
						</div>
					</Col>
				</Row>
			</NewModel>
		)
	}

	// Order table
	const renderOrderTable = () => {
		return (
			<>
				<Table striped style={{ fontSize: 14, alignItems: '' }} responsive="lg">
					<thead>
						<tr>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									#
								</Typography>
							</th>
							{/* <th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Order ID
								</Typography>
							</th> */}
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									PO Date
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									PO Number
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Customer
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Supplier
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Delivery Date
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Status
								</Typography>
							</th>
							<th style={{ verticalAlign: 'baseline' }}>
								<Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
									Actions
								</Typography>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							orderArr.length > 0 ?
								orderArr.map((order, index) =>
									<tr key={index}>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{index + 1}
											</Typography>
										</td>
										{/* <td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.OID}
											</Typography>
										</td> */}
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.PO_DATE}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.PO_NUMBER}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.CUSTOMER}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.SUPPLIER}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.DELEVERY_DATE}
											</Typography>
										</td>
										<td>
											<Typography sx={{ color: theme.palette.primary[200] }}>
												{order.Status}
											</Typography>
										</td>
										<td>
											{
												<div style={{ display: 'flex' }}>
													<Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
														<IconButton onClick={() => showOrderDetailsModal(order)} >
															<MdRemoveRedEye style={{ color: theme.palette.secondary[300] }} />
														</IconButton>
													</Typography>
													<Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
														<IconButton onClick={() => showOrdUpModal(order)} >
															<MdEdit style={{ color: theme.palette.secondary[300] }} />
														</IconButton>
													</Typography>
													<Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
														<IconButton onClick={() => showOrdRemModal(order)} >
															<MdDelete style={{ color: theme.palette.secondary[300] }} />
														</IconButton>
													</Typography>

												</div>
											}
										</td>
									</tr>

								)
								:
								null
						}

					</tbody>
				</Table>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }} >
					<Stack spacing={2} >
						<Pagination count={Math.ceil(orders.length / 10)} color="secondary" page={page} onChange={handleChange} />
					</Stack>
				</div>
			</>
		)
	}

	if (!authenticated) {
		return <Navigate to='/' />
	}

	return (
		<motion.div
			whileInView={{ opacity: [0, 1] }}
			transition={{ duration: .75, ease: 'easeInOut' }}
			initial={{ opacity: 0 }}
		>
			<Container>
				<Row>
					<Col md={12}>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '50px' }} >
							<Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
								ORDERS
							</Typography>

							<button className='form-control' style={{ width: 'auto', margin: '0 10px' }} onClick={(e) => setShowAddModal(true)} >Add New Order</button>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
							<div style={{ display: 'flex', height: '2rem', alignItems: 'center' }}>
								<IconButton onClick={() => dispatch(getOrders())}>
									<MdRefresh />
								</IconButton>
								<Typography onClick={() => dispatch(getOrders())} style={{ cursor: 'pointer' }} >Refresh</Typography>
							</div>
							<FlexBetween
								backgroundColor={theme.palette.background.alt}
								borderRadius="9px"
								gap="3px"
								padding="0.1rem 1.5rem"
							>
								<InputBase
									placeholder='Search...'
									onChange={(e) => setSearchText(e.target.value.toLowerCase())}
								/>
								<IconButton>
									<Search />
								</IconButton>
							</FlexBetween>
						</div>

					</Col>
				</Row>
				<Row>
					<Col>
						{
							loading ?
								<div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<ThreeDots
										height="80"
										width="80"
										radius="9"
										color={theme.palette.primary[200]}
										ariaLabel="three-dots-loading"
										wrapperStyle={{}}
										wrapperClassName=""
										visible={true}
									/>
								</div>
								:
								orders.length ?
									<motion.div
										whileInView={{ opacity: [0, 1] }}
										transition={{ duration: .75, ease: 'easeInOut' }}
										initial={{ opacity: 0 }}
									>
										{renderOrderTable()}
									</motion.div>

									:
									<div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
											No Orders
										</Typography>
									</div>
						}
					</Col>
				</Row>
			</Container>
			{renderOrderAddModal()}
			{renderOrderRemoveModal()}
			{renderUpdateOrdModal()}
			{renderOrderDetailModal()}
		</motion.div>
	);
}

export default Orders;