import { orderConsts } from "actions/constants";

const initState = {
    loading: false,
    deleting: false,
    updating: false,
    adding: false,
    orders: []
}

export default (state = initState, action) => {
    switch(action.type){
        default:
            state = {
                ...initState
            }
            break
        case orderConsts.ORDER_FETCH_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case orderConsts.ORDER_FETCH_SUCCESS:
            state = {
                ...state,
                loading: false,
                orders: action.payload
            }
            break
        case orderConsts.ORDER_FETCH_FAILED:
            state = {
                ...state,
                loading: false
            }
            break
        case orderConsts.ORDER_ADD_REQUEST:
            state = {
                ...state,
                adding: true
            }
            break
        case orderConsts.ORDER_ADD_SUCCESS:
            state ={
                ...state,
                adding: false,
                orders: action.payload
            }
            break
        case orderConsts.ORDER_ADD_FAILED:
            state = {
                ...state,
                adding: false
            }
            break
        case orderConsts.ORDER_UPDATE_REQUEST:
            state = {
                ...state,
                updating: true
            }
            break
        case orderConsts.ORDER_UPDATE_SUCCESS:
            state = {
                ...state,
                updating: false,
                orders: action.payload
            }
            break
        case orderConsts.ORDER_UPDATE_FAILED:
            state = {
                ...state,
                updating: false
            }
            break
        case orderConsts.ORDER_REMOVE_REQUEST:
            state = {
                ...state,
                deleting: true
            }
            break
        case orderConsts.ORDER_REMOVE_SUCCESS:
            state = {
                ...state,
                deleting: false,
                orders: action.payload
            }
            break
        case orderConsts.ORDER_REMOVE_FAILED:
            state = {
                ...state,
                deleting: false
            }
            break
    }
    return state
}