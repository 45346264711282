import { customerAccountStatusUpdate, getCustomerDetails, deleteCustomerAccount } from 'actions/customerActions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import { Col, Container, Row, Table } from 'react-bootstrap'
import { NewModel } from 'components/Modal'
import { Typography } from '@mui/material'
import { useTheme, IconButton, InputBase } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FlexBetween from 'components/FlexBetween'
import { Search } from '@mui/icons-material';
import { ThreeDots } from 'react-loader-spinner';
import { MdRemoveRedEye, MdDelete, MdRefresh } from 'react-icons/md'
import { toast } from 'react-hot-toast'
import { motion } from 'framer-motion'

const CustDetails = () => {
    const { cid } = useParams()
    const dispatch = useDispatch()
    const mode = useSelector(state => state.general.mode)
    const authenticated = useSelector(state => state.auth.authenticated)

    // Initial Data fetching
    const form = {
        'CID': cid
    }
    useEffect(() => {
        dispatch(getCustomerDetails(form))
    }, [mode]);

    const loading = useSelector(state => state.customer.loading)
    const deleting = useSelector(state => state.customer.deleting)
    const updating = useSelector(state => state.customer.updating)
    const adding = useSelector(state => state.customer.adding)

    useEffect(() => {
        if (adding === true) {
            toast.loading('Processing...', {
                id: 'adding'
            })
        }
        else if (adding === false) {
            toast.dismiss('adding')
        }

    }, [adding]);

    useEffect(() => {
        if (updating === true) {
            toast.loading('Updating...', {
                id: 'Updating'
            })
        }
        else if (updating === false) {
            toast.dismiss('Updating')
        }

    }, [updating]);

    useEffect(() => {
        if (deleting === true) {
            toast.loading('Deleting...', {
                id: 'Deleting'
            })
        }
        else if (deleting === false) {
            toast.dismiss('Deleting')
        }

    }, [deleting]);

    useEffect(() => {
        if (loading === true) {
            toast.loading('Processing...', {
                id: 'loading'
            })
        }
        else if (loading === false) {
            toast.dismiss('loading')
        }

    }, [loading]);

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const customers = useSelector(state => state.customer.customerAccounts)
    const [custArr, setCustArr] = useState([]);
    const theme = useTheme()

    useEffect(() => {
        if (searchText !== '') {
            const resultsArray = customers.filter(customer =>
                customer.AccountID.toLowerCase().includes(searchText) ||
                customer.FullName.toLowerCase().includes(searchText) ||
                customer.BusinessEmail.toLowerCase().includes(searchText)
            )
            setCustArr(resultsArray.slice(0, 9))
        }
        if (searchText === '' && searchText.length === 0) {
            setCustArr(customers)
        }
    }, [searchText])

    useEffect(() => {
        if (page === 1) {
            if (customers?.length < 11) {
                const customerArray = customers.slice(0, customers.length)
                setCustArr(customerArray)
            }
            else {
                const customerArray = customers?.slice(0, 10)
                setCustArr(customerArray)
            }

        }
        else {
            if ((page - 1) * 10 + 10 > customers?.length) {
                const customerArray = customers.slice((page - 1) * 10, customers.length)
                setCustArr(customerArray)
            }
            {
                const customerArray = customers.slice((page - 1) * 10, (page - 1) * 10 + 10)
                setCustArr(customerArray)
            }

        }
    }, [page, customers]);
    const handleChange = (e, v) => {
        setPage(v)
    }

    const customerAccStatusUpdate = (state, customer) => {
        const form = {
            'CID': customer.CID,
            'accID': customer.AccountID,
            'Status': state
        }
        dispatch(customerAccountStatusUpdate(form))
    }

    const [custDetails, setCustDetails] = useState(undefined);
    const [delCustModal, setDelCustModal] = useState(false);
    const deleteCustomer = () => {
		setDelCustModal(false)
		setCustDetails(undefined)
        const form = {
            "accID": custDetails.AccountID,
            "CID": custDetails.CID
        }
		dispatch(deleteCustomerAccount(form))
	}

	const delClose = () => {
		setCustDetails(undefined)
		setDelCustModal(false)
	}

	const delCustShow = (customer) => {
		setCustDetails(customer)
		setDelCustModal(true)
	}

	const delCustomer = () => {
		if (!custDetails) {
			return null
		}
		return (
			<NewModel ModalTitle='Confirm Action'
				show={delCustModal}
				handleClose={deleteCustomer}
				close={delClose}
				buttons={[
					{
						label: 'No',
						color: 'secondary',
						onClick: delClose
					},
					{
						label: 'Yes',
						color: 'danger',
						onClick: deleteCustomer
					}
				]}
			>
				<Typography sx={{ color: theme.palette.primary[600], fontWeight: 'bold', fontSize: '1rem' }}>
					Are you sure you want to delete customer account?
				</Typography>
				<Typography sx={{ color: theme.palette.primary[300] }}>
					{custDetails.FullName}
				</Typography>
			</NewModel>
		)
	}

    const renderCustomerTable = () => {
        return (
            <>
                <Table style={{ fontSize: 14, alignItems: '' }} striped responsive="lg">
                    <thead>
                        <tr>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    #
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Account ID
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Full Name
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Email
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Job Title
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Contact No
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Status
                                </Typography>
                            </th>
                            <th style={{ verticalAlign: 'baseline' }}>
                                <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                    Actions
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            custArr?.length > 0 ?
                                custArr.map((customer, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {index + 1}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {customer.AccountID}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {customer.FullName}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {customer.BusinessEmail}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {customer.JobTitle}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {customer.ContactNo}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                <select
                                                    style={{ width: 'auto' }}
                                                    className='form-control'
                                                    value={customer.Status}
                                                    onChange={(e) => { customerAccStatusUpdate(e.target.value, customer) }}
                                                >
                                                    <option value="Disabled" >Disabled</option>
                                                    <option value="Enabled" >Enabled</option>
                                                </select>
                                            </Typography>
                                        </td>
                                        <td>
                                            {
                                                <div style={{ display: 'flex' }}>
                                                    {/* <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                        <IconButton  >
                                                            <MdRemoveRedEye style={{ color: theme.palette.secondary[300] }} />
                                                        </IconButton>
                                                    </Typography> */}
                                                    <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                        <IconButton onClick={() => delCustShow(customer)} >
                                                            <MdDelete style={{ color: theme.palette.secondary[300] }} />
                                                        </IconButton>
                                                    </Typography>

                                                </div>
                                            }
                                        </td>
                                    </tr>

                                )
                                : null
                        }

                    </tbody>
                </Table>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }} >
                    <Stack spacing={2} style={{}} >
                        <Pagination count={Math.ceil(customers?.length / 10)} color="secondary" page={page} onChange={handleChange} />
                    </Stack>
                </div>
            </>
        )
    }
    if (!authenticated) {
		return <Navigate to='/' />
	}

    return (
        <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: .75, ease: 'easeInOut' }}
            initial={{ opacity: 0 }}
        >
            <Container>
                <Row>
                    <Col md={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '50px' }} >
                            <Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
                                CUSTOMER ACCOUNTS
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', height: '2rem', alignItems: 'center' }}>
                                <IconButton onClick={() => dispatch(getCustomerDetails(form))}>
                                    <MdRefresh />
                                </IconButton>
                                <Typography onClick={() => dispatch(getCustomerDetails(form))} style={{ cursor: 'pointer' }} >Refresh</Typography>
                            </div>
                            <FlexBetween
                                backgroundColor={theme.palette.background.alt}
                                borderRadius="9px"
                                gap="3px"
                                padding="0.1rem 1.5rem"
                            >
                                <InputBase
                                    placeholder='Search...'
                                    onChange={(e) => setSearchText(e.target.value.toString())}
                                />
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </FlexBetween>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            loading ?
                                <div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color={theme.palette.primary[200]}
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                                :
                                customers?.length > 0 ?
                                    <motion.div
                                        whileInView={{ opacity: [0, 1] }}
                                        transition={{ duration: .75, ease: 'easeInOut' }}
                                        initial={{ opacity: 0 }}
                                    >
                                        {renderCustomerTable()}
                                    </motion.div>

                                    :
                                    <div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
                                            No Customer Accounts Found
                                        </Typography>
                                    </div>
                        }
                    </Col>
                </Row>
                    {delCustomer()}
            </Container>
        </motion.div>
    );
}

export default CustDetails;
