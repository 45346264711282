import { combineReducers } from "redux";
import authReducers from "./authReducers";
import generalReducers from "./generalReducers";
import adminReducers from "./adminReducers";
import inquiryReducers from "./inquiryReducers";
import customerReducers from "./customerReducers";
import orderReducers from "./orderReducers";

const rootReducer = combineReducers({
    auth: authReducers,
    general: generalReducers,
    admin: adminReducers,
    customer: customerReducers,
    inquiry: inquiryReducers,
    order: orderReducers
})

export default rootReducer