import { toast } from "react-hot-toast"
import axiosInstance from "../helpers/axios"
import { inquiryConsts } from "./constants"

export const getAllInquiries = () => {
    return async dispatch => {
        dispatch({
            type: inquiryConsts.GET_ALL_INQUIRIES_REQUEST
        })
        const res = await axiosInstance.get("/inquiry/get")

        if(res.status === 200){
            toast.success("Inquiry list updated!", {
                id: 'InqListUpdate'
            })
            const inquiries = res.data.payload
            dispatch({
                type: inquiryConsts.GET_ALL_INQUIRIES_SUCCESS,
                payload: inquiries
            })
        }
        else if(res.status === 400 || res.status === 404){
            dispatch({
                type: inquiryConsts.GET_ALL_INQUIRIES_FAILED,
                payload: res.data.message
            })
        }
    }
}

export const deleteInquiries = (id) => {
    return async dispatch => {
        dispatch({
            type: inquiryConsts.INQUIRY_DELETE_REQUEST
        })
        const res = await axiosInstance.post("/inquiry/delete", id)

        if(res.status === 201){
            toast.success("Inquiry Removed")
            dispatch({
                type: inquiryConsts.INQUIRY_DELETE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.status === 404){
            toast.error("Inquiry Removal Failed!")
            dispatch({
                type: inquiryConsts.INQUIRY_DELETE_FAILED,
                payload: res.data.message
            })
        }
    }
}

export const updateInqStatus = (form) => {
    return async dispatch => {
        dispatch({
            type: inquiryConsts.INQUIRY_STATUS_UPDATE_REQUEST
        })
        const res = await axiosInstance.post("/inquiry/status-update", form)

        if(res.status === 200){
            toast.success(res.data.message)
            dispatch({
                type: inquiryConsts.INQUIRY_STATUS_UPDATE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.status === 500){
            toast.error(res.data.message)
            dispatch({
                type: inquiryConsts.INQUIRY_STATUS_UPDATE_FAILED
            })
        }
    }
}

export const updateInquiry = (form) => {
    return async dispatch => {
        dispatch({
            type: inquiryConsts.INQUIRY_UPDATE_REQEUST
        })
        const res = await axiosInstance.post("/inquiry/update", form)
        if (res.status === 200){
            toast.success("Inquiry Updated")
            dispatch({
                type: inquiryConsts.INQUIRY_UPDATE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if (res.response.status === 404){
            toast.error("No inquiry found")
            dispatch({
                type: inquiryConsts.INQUIRY_UPDATE_FAILED
            })
        }
        else {
            toast.error("Something went wrong")
            dispatch({
                type: inquiryConsts.INQUIRY_UPDATE_FAILED
            })
        }
    }
}