export const authConsts = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_FALIURE: 'LOGIN_FALIURE',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILED: 'LOGOUT_FAILED'
}

export const orderConsts = {
    ORDER_ADD_REQUEST: "ORDER_ADD_REQUEST",
    ORDER_ADD_SUCCESS: "ORDER_ADD_SUCCESS",
    ORDER_ADD_FAILED: "ORDER_ADD_FAILED",
    ORDER_FETCH_REQUEST: "ORDER_FETCH_REQUEST",
    ORDER_FETCH_SUCCESS: "ORDER_FETCH_SUCCESS",
    ORDER_FETCH_FAILED: "ORDER_FETCH_FAILED",
    ORDER_UPDATE_REQUEST: "ORDER_UPDATE_REQUEST",
    ORDER_UPDATE_SUCCESS: "ORDER_UPDATE_SUCCESS",
    ORDER_UPDATE_FAILED: "ORDER_UPDATE_FAILED",
    ORDER_REMOVE_REQUEST: "ORDER_REMOVE_REQUEST",
    ORDER_REMOVE_SUCCESS: "ORDER_REMOVE_SUCCESS",
    ORDER_REMOVE_FAILED: "ORDER_REMOVE_FAILED"
}

export const customerConsts = {
    CUSTOMER_ADD_REQUEST: 'CUSTOMER_ADD_REQUEST',
    CUSTOMER_ADD_SUCCESS: 'CUSTOMER_ADD_SUCCESS',
    CUSTOMER_ADD_FAILED: 'CUSTOMER_ADD_FAILED',
    CUSTOMER_GET_ALL_REQUEST: "CUSTOMER_GET_ALL_REQUEST",
    CUSTOMER_GET_ALL_SUCCESS: 'CUSTOMER_GET_ALL_SUCCESS',
    CUSTOMER_GET_ALL_FAILED: 'CUSTOMER_GET_ALL_FAILED',
    CUSTOMER_REMOVE_REQUEST: "CUSTOMER_REMOVE_REQUEST",
    CUSTOMER_REMOVE_SUCCESS: "CUSTOMER_REMOVE_SUCCESS",
    CUSTOMER_REMOVE_FAILED: "CUSTOMER_REMOVE_FAILED",
    CUSTOMER_UPDATE_REQUEST: "CUSTOMER_UPDATE_REQUEST",
    CUSTOMER_UPDATE_SUCCESS: "CUSTOMER_UPDATE_SUCCESS",
    CUSTOMER_UPDATE_FAILED: "CUSTOMER_UPDATE_FAILED",
    CUSTOMER_STATUS_UPDATE_REQUEST: "CUSTOMER_STATUS_UPDATE_REQUEST",
    CUSTOMER_STATUS_UPDATE_SUCCESS: "CUSTOMER_STATUS_UPDATE_SUCCESS",
    CUSTOMER_STATUS_UPDATE_FAILED: "CUSTOMER_STATUS_UPDATE_FAILED",
    CUSTOMER_DETAILS_FETCH_REQUEST: "CUSTOMER_DETAILS_FETCH_REQUEST",
    CUSTOMER_DETAILS_FETCH_SUCCESS: "CUSTOMER_DETAILS_FETCH_SUCCESS",
    CUSTOMER_DETAILS_FETCH_FAILED: "CUSTOMER_DETAILS_FETCH_FAILED",
    CUSTOMER_ACCOUNT_STATUS_UPDATE_REQUEST: "CUSTOMER_ACCOUNT_STATUS_UPDATE_REQUEST",
    CUSTOMER_ACCOUNT_STATUS_UPDATE_SUCCESS: "CUSTOMER_ACCOUNT_STATUS_UPDATE_SUCCESS",
    CUSTOMER_ACCOUNT_STATUS_UPDATE_FAILED: "CUSTOMER_ACCOUNT_STATUS_UPDATE_FAILED",
    CUSTOMER_ACCOUNT_DELETE_REQUEST: "CUSTOMER_ACCOUNT_DELETE_REQUEST",
    CUSTOMER_ACCOUNT_DELETE_SUCCESS: "CUSTOMER_ACCOUNT_DELETE_SUCCESS",
    CUSTOMER_ACCOUNT_DELETE_FAILED: "CUSTOMER_ACCOUNT_DELETE_FAILED"
}

export const chatConsts = {
    CHAT_SUBMIT_REQUEST: "CHAT_SUBMIT_REQUEST",
    CHAT_SUBMIT_SUCCESS: "CHAT_SUBMIT_SUCCESS",
    CHAT_SUBMIT_FAILED: "CHAT_SUBMIT_FAILED",
    CHAT_FETCH_REQUEST: "CHAT_FETCH_REQUEST",
    CHAT_FETCH_SUCCESS: "CHAT_FETCH_SUCCESS",
    CHAT_FETCH_FAILED: "CHAT_FETCH_FAILED",
    CHAT_FEED_CLEARED: "CHAT_FEED_CLEARED",
    CHAT_MSG_DELETE_REQUEST: "CHAT_MSG_DELETE_REQUEST",
    CHAT_MSG_DELETE_SUCCESS: "CHAT_MSG_DELETE_SUCCESS",
    CHAT_MSG_DELETE_FAILED: "CHAT_MSG_DELETE_FAILED"
}

export const inquiryConsts = {
    GET_ALL_INQUIRIES_REQUEST: "GET_ALL_INQUIRIES_REQUEST",
    GET_ALL_INQUIRIES_FAILED: "GET_ALL_INQUIRIES_FAILED",
    GET_ALL_INQUIRIES_SUCCESS: "GET_ALL_INQUIRIES_SUCCESS",
    INQUIRY_CHAT_ADDED: "INQUIRY_CHAT_ADDED",
    INQUIRY_CHAT_ADD_FAILED: "INQUIRY_CHAT_ADD_FAILED",
    INQUIRY_DELETE_REQUEST: "INQUIRY_DELETE_REQUEST",
    INQUIRY_DELETE_FAILED: "INQUIRY_DELETE_FAILED",
    INQUIRY_DELETE_SUCCESS: "INQUIRY_DELETE_SUCCESS",
    INQUIRY_STATUS_UPDATE_REQUEST: "INQUIRY_STATUS_UPDATE_REQUEST",
    INQUIRY_STATUS_UPDATE_SUCCESS: "INQUIRY_STATUS_UPDATE_SUCCESS",
    INQUIRY_STATUS_UPDATE_FAILED: "INQUIRY_STATUS_UPDATE_FAILED",
    INQUIRY_UPDATE_REQEUST: "INQUIRY_UPDATE_REQEUST",
    INQUIRY_UPDATE_FAILED: "INQUIRY_UPDATE_FAILED",
    INQUIRY_UPDATE_SUCCESS: "INQUIRY_UPDATE_SUCCESS"
}

export const adminConsts = {
    ADMIN_REGISTRATION_REQUEST: "ADMIN_REGISTRATION_REQUEST",
    ADMIN_REGISTRATION_SUCCESS: "ADMIN_REGISTRATION_SUCCESS",
    ADMIN_REGISTRATION_FAILED: "ADMIN_REGISTRATION_FAILED",
    GET_ALL_ADMINS_REQUEST: "GET_ALL_ADMINS_REQUEST",
    GET_ALL_ADMINS_SUCCESS: "GET_ALL_ADMINS_SUCCESS",
    GET_ALL_ADMINS_FAILED: "GET_ALL_ADMINS_FAILED",
    ADMIN_REMOVE_REQUEST: "ADMIN_REMOVE_REQUEST",
    ADMIN_REMOVE_SUCCESS: "ADMIN_REMOVE_SUCCESS",
    ADMIN_REMOVE_FAILED: "ADMIN_REMOVE_FAILED"
}

export const generalConstatnts = {
    MODE_CHANGE_REQUEST: "MODE_CHANGE_REQUEST",
    SET_MODE_DARK: "SET_MODE_DARK",
    SET_MODE_LIGHT: 'SET_MODE_LIGHT'
}
  