import { chatConsts } from "./constants";
import { toast } from "react-hot-toast"
import axiosInstance from "../helpers/axios"

export const submitChat = (form) => {
    return async dispatch => {
        dispatch({
            type: chatConsts.CHAT_SUBMIT_REQUEST
        })
        const res = await axiosInstance.post("/chat/addmsg", form)
        if(res.status === 200){
            toast.success("Message Sent")
            dispatch({
                type: chatConsts.CHAT_SUBMIT_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 500){
            toast.error('Chat submit failure!', {id: 'chatSubFail'})
            dispatch({
                type: chatConsts.CHAT_SUBMIT_FAILED
            })
        }
    }
}

export const getAllChats = (form) => {
    return async dispatch => {
        dispatch({
            type: chatConsts.CHAT_FETCH_REQUEST
        })
        const res = await axiosInstance.post('/chat/getall', form)

        if(res.status === 200){
            dispatch({
                type: chatConsts.CHAT_FETCH_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 500){
            dispatch({
                type: chatConsts.CHAT_FETCH_FAILED
            })
        }
    }
}

export const clearInqChat = () => {
    return async dispatch => {
        dispatch({
            type: chatConsts.CHAT_FEED_CLEARED
        })
    }
}

export const deleteChatMsg = (form) => {
    return async dispatch => {
        dispatch({
            type: chatConsts.CHAT_MSG_DELETE_REQUEST,
        })
        const res = await axiosInstance.post("/chat/delete", form)
        if(res.status === 200){
            toast.success("Message Deleted")
            dispatch({
                type: chatConsts.CHAT_MSG_DELETE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 500){
            toast.error("Message Deletion Failed!")
            dispatch({
                type: chatConsts.CHAT_MSG_DELETE_FAILED
            })
        }
    }
}