import axiosInstance from "helpers/axios";
import { orderConsts } from "./constants";
import { toast } from "react-hot-toast";

export const addOrder = (form) => {
    return async dispatch => {
        dispatch({
            type: orderConsts.ORDER_ADD_REQUEST
        })
        const res = await axiosInstance.post("/order/add", form)

        if(res.status === 201){
            toast.success("Order Added")
            dispatch({
                type: orderConsts.ORDER_ADD_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 500){
            toast.error("Order add failure!")
            dispatch({
                type: orderConsts.ORDER_ADD_FAILED
            })
        }
        else if(res.response.status === 401){
            toast.error(`${res.response.data.message}`)
            dispatch({
                type: orderConsts.ORDER_ADD_FAILED
            })
        }
    }
}

export const getOrders = () => {
    return async dispatch => {
        dispatch({
            type: orderConsts.ORDER_FETCH_REQUEST
        })

        const res = await axiosInstance.get("/order/get")

        if(res.status === 200){
            toast.success("Order list updated!", {
                id: 'orderListUpdate'
            })
            dispatch({
                type: orderConsts.ORDER_FETCH_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 500){
            toast.error(`${res.response.data.message}`)
            dispatch({
                type: orderConsts.ORDER_FETCH_FAILED
            })
        }
    }
}

export const updateOrder = (form) => {
    return async dispatch => {
        dispatch({
            type: orderConsts.ORDER_UPDATE_REQUEST
        })
        const res = await axiosInstance.post("/order/update", form)

        if(res.status === 201){
            toast.success("Order updated!")
            dispatch({
                type: orderConsts.ORDER_UPDATE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 404){
            toast.error(`${res.response.data.message}`)
            dispatch({
                type: orderConsts.ORDER_UPDATE_FAILED
            })
        }
        else if(res.response.status === 500){
            toast.error(`${res.response.data.message}`)
            dispatch({
                type: orderConsts.ORDER_UPDATE_FAILED
            })
        }
    }
}

export const deleteOrder = (form) => {
    return async dispatch => {
        dispatch({
            type: orderConsts.ORDER_REMOVE_REQUEST
        })
        const res = await axiosInstance.post("/order/delete", form)

        if(res.status === 201){
            toast.success("Order Removed!")
            dispatch({
                type: orderConsts.ORDER_REMOVE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if(res.response.status === 500){
            toast.error(`${res.response.data.message}`)
            dispatch({
                type: orderConsts.ORDER_UPDATE_FAILED
            })
        }
        else if(res.response.status === 404){
            toast.error(`${res.response.data.message}`)
            dispatch({
                type: orderConsts.ORDER_UPDATE_FAILED
            })
        }
    }
}