import axiosInstance from "helpers/axios";
import { adminConsts } from "./constants";
import { toast } from "react-hot-toast";

export const adminRegister = (user) => {

    return async (dispatch) => {
        dispatch({ type: adminConsts.ADMIN_REGISTRATION_REQUEST })
        const response = await axiosInstance.post('/admin/signup', {
            ...user
        })
        if (response.status === 201) {
            toast.success("Admin Registered")
            dispatch({
                type: adminConsts.ADMIN_REGISTRATION_SUCCESS,
                payload: response.data.payload
            })
        }
        else if (response.status === 400) {
            toast.error("Something Went Wrong")
            dispatch({
                type: adminConsts.ADMIN_REGISTRATION_FAILED
            })
        }
        else if (response.response.status === 401){
            toast.error("Super Administrator Access Needed")
            dispatch({
                type: adminConsts.ADMIN_REGISTRATION_FAILED
            })
        }
        else if (response.response.status === 400){
            toast.error("Something Went Wrong")
            dispatch({
                type: adminConsts.ADMIN_REGISTRATION_FAILED
            })
        }
    }
}

export const getAllAdmins = () => {
    return async dispatch => {
        dispatch({
            type: adminConsts.GET_ALL_ADMINS_REQUEST
        })
        const res = await axiosInstance.get('/admin/getall')
        if (res.status === 200) {
            toast.success("Admin list updated!", {id: 'adminRs'})
            const admins = res.data.payload;
            dispatch({
                type: adminConsts.GET_ALL_ADMINS_SUCCESS,
                payload: admins,
            });
        }
    };
};

export const removeAdmin = (email, curEmail, role) => {
    if (email === curEmail) {
        toast.error("Cannot Remove Current Account...")
    }
    else{
        const form = {
            email: email
        }
        return async dispatch => {
            dispatch({
                type: adminConsts.ADMIN_REMOVE_REQUEST
            })
            const res = await axiosInstance.post('/admin/delete', form)
            if (res.status === 201 ){
                toast.success("Admin Removed", {
                    id: 'delSuccess'
                })
                dispatch({
                    type: adminConsts.ADMIN_REMOVE_SUCCESS,
                    payload: res.data.payload
                })
            }
            else if (res.response.status === 401 ){
                toast.error("Super Administrator privileges required...", {
                    id: 'delFail1'
                })
                dispatch({
                    type: adminConsts.ADMIN_REMOVE_FAILED
                })
            }
            else if (res.status === 400){
                toast.error("Something Went Wrong...", {
                    id: 'delFail2'
                })
                dispatch({
                    type: adminConsts.ADMIN_REMOVE_FAILED
                })
            }
            else if (res.response.status === 404){
                toast.error("Something Went Wrong...", {
                    id: 'delFail2'
                })
                dispatch({
                    type: adminConsts.ADMIN_REMOVE_FAILED
                })
            }
            else if (res.response.status === 400){
                toast.error("Something Went Wrong...", {
                    id: 'delFail2'
                })
                dispatch({
                    type: adminConsts.ADMIN_REMOVE_FAILED
                })
            }
        }
    }
    
}
