import { customerConsts } from "actions/constants";

const initState = {
    customers: [],
    loading: false,
    deleting: false,
    updating: false,
    adding: false,
    customerDetails: {},
    customerAccounts: []
}

export default (state = initState, action) => {
    switch (action.type) {
        default:
            state = {
                ...initState
            }
            break
        case customerConsts.CUSTOMER_GET_ALL_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case customerConsts.CUSTOMER_GET_ALL_SUCCESS:
            state = {
                ...state,
                loading: false,
                customers: action.payload
            }
            break
        case customerConsts.CUSTOMER_GET_ALL_FAILED:
            state = {
                ...state,
                loading: false
            }
            break
        case customerConsts.CUSTOMER_ADD_REQUEST:
            state = {
                ...state,
                adding: true
            }
            break
        case customerConsts.CUSTOMER_ADD_SUCCESS:
            state = {
                ...state,
                customers: action.payload,
                adding: false
            }
            break
        case customerConsts.CUSTOMER_ADD_FAILED:
            state = {
                ...state,
                adding: false
            }
            break
        case customerConsts.CUSTOMER_REMOVE_REQUEST:
            state = {
                ...state,
                deleting: true
            }
            break
        case customerConsts.CUSTOMER_REMOVE_SUCCESS:
            state = {
                ...state,
                customers: action.payload,
                deleting: false
            }
            break
        case customerConsts.CUSTOMER_REMOVE_FAILED:
            state = {
                ...state,
                deleting: false
            }
            break
        case customerConsts.CUSTOMER_STATUS_UPDATE_REQUEST:
            state = {
                ...state,
                updating: true
            }
            break
        case customerConsts.CUSTOMER_STATUS_UPDATE_SUCCESS:
            state = {
                ...state,
                updating: false,
                customers: action.payload
            }
            break
        case customerConsts.CUSTOMER_STATUS_UPDATE_FAILED:
            state = {
                ...state,
                updating: false
            }
            break
        case customerConsts.CUSTOMER_UPDATE_REQUEST:
            state = {
                ...state,
                updating: true
            }
            break
        case customerConsts.CUSTOMER_UPDATE_SUCCESS:
            state = {
                ...state,
                updating: false,
                customers: action.payload
            }
            break
        case customerConsts.CUSTOMER_UPDATE_FAILED:
            state = {
                ...state,
                updating: false
            }
            break
        case customerConsts.CUSTOMER_DETAILS_FETCH_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case customerConsts.CUSTOMER_DETAILS_FETCH_SUCCESS:
            state = {
                ...state,
                customerDetails: action.payload,
                customerAccounts: action.payload.accounts,
                loading: false
            }
            break
        case customerConsts.CUSTOMER_DETAILS_FETCH_FAILED:
            state = {
                ...state,
                loading: false
            }
            break
        case customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_REQUEST:
            state = {
                ...state,
                updating: true
            }
            break
        case customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_SUCCESS:
            state = {
                ...state,
                updating: false,
                customerAccounts: action.payload
            }
            break
        case customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_FAILED:
            state = {
                ...state,
                updating: false
            }
            break
        case customerConsts.CUSTOMER_ACCOUNT_DELETE_REQUEST:
            state = {
                ...state,
                deleting: true
            }
            break
        case customerConsts.CUSTOMER_ACCOUNT_DELETE_SUCCESS:
            state = {
                ...state,
                deleting: false,
                customerAccounts: action.payload
            }
            break
    }
    return state
}
