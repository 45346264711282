import { Typography, IconButton } from "@mui/material";
import { MdDelete } from "react-icons/md";
import React, { useState, useEffect } from "react";
import { Table } from 'react-bootstrap'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { NewModel } from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { removeAdmin } from "actions/adminActions";

const PaginationTable = (props) => {
    const [elementArray, setElementArray] = useState([]);
    const theme = props.theme
    const [page, setPage] = useState(1);
    const headings = props.headings
    const [delAdModal, setDelAdModal] = useState(false);
    const [adDetes, setAdDetes] = useState(null);
    const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch()
    const handleChange = (e, v) => {
        setPage(v)
    }
    useEffect(() => {
        if (page === 1) {
            if (props.Array.length < props.pageSize) {
                const arr = props.Array.slice(0, props.Array.length)
                setElementArray(arr)
            }
            else {
                const arr = props.Array.slice(0, props.pageSize)
                setElementArray(arr)
            }

        }
        else {
            if ((page - 1) * 8 + 8 > props.Array.length) {
                const arr = props.Array.slice((page - 1) * props.pageSize, props.Array.length)
                setElementArray(arr)
            }
            {
                const arr = props.Array.slice((page - 1) * props.pageSize, (page - 1) * props.pageSize + props.pageSize)
                setElementArray(arr)
            }

        }
    }, [page, props.Array]);

    const delAdminShow = (element) => {
        setAdDetes(element)
        setDelAdModal(true)
    }

    const delClose = () => {
        setDelAdModal(false)
        setAdDetes(null)
    }

    const deleteAdmin = () => {
        dispatch(removeAdmin(adDetes.email, user.email, user.role))
        setAdDetes(null)
        setDelAdModal(false)
    }

    const delAdmin = () => {
        if (!adDetes) {
            return null
        }
        return (
            <NewModel ModalTitle='Confirm Action'
                show={delAdModal}
                handleClose={deleteAdmin}
                close={delClose}
                buttons={[
                    {
                        label: 'No',
                        color: 'secondary',
                        onClick: delClose
                    },
                    {
                        label: 'Yes',
                        color: 'danger',
                        onClick: deleteAdmin
                    }
                ]}
            >
                <Typography sx={{ color: theme.palette.primary[600], fontWeight: 'bold', fontSize: '1rem' }}>
                    Are you sure you want to delete the administrator account
                </Typography>
                <Typography sx={{ color: theme.palette.primary[300] }}>
                    {adDetes.email}
                </Typography>
            </NewModel>
        )
    }
    return (
        <>
        {delAdmin()}
            <div >
                <Table style={{ fontSize: 14, alignItems: 'center' }}striped responsive="lg">
                    <thead>
                        <tr>
                            {
                                headings.map((heading, index) =>
                                    <th style={{ verticalAlign: 'baseline' }} key={index}>
                                        <Typography fontWeight='bold' sx={{ color: theme.palette.primary[200] }}>
                                            {heading}
                                        </Typography>
                                    </th>
                                )
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.Array.length > 0 ?
                                elementArray.map((element, index) =>
                                    <tr key={index} >
                                        <td style={{ verticalAlign: 'baseline' }}>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {(index + 1) + (page - 1) * props.pageSize}
                                            </Typography>
                                        </td>
                                        <td style={{ verticalAlign: 'baseline' }}>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {element.firstName}
                                            </Typography>
                                        </td>
                                        <td style={{ verticalAlign: 'baseline' }}>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {element.lastName}
                                            </Typography>
                                        </td>
                                        <td style={{ verticalAlign: 'baseline' }}>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {element.email}
                                            </Typography>
                                        </td>
                                        <td style={{ verticalAlign: 'baseline' }}>
                                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                                {element.contact}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ color: theme.palette.primary[200], fontSize: '1rem' }}>
                                                <IconButton onClick={() => delAdminShow(element)} >
                                                    <MdDelete style={{ color: theme.palette.secondary[300] }} />
                                                </IconButton>
                                            </Typography>
                                        </td>
                                    </tr>

                                )
                                : null
                        }

                    </tbody>
                </Table>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', bottom: '2%' }} >
                    <Stack spacing={2} style={{}} >
                        <Pagination count={Math.ceil(props.Array.length / props.pageSize)} color="secondary" page={page} onChange={handleChange} />
                    </Stack>
                </div>
            </div>

        </>
    )
}

export default PaginationTable