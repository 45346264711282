import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import PaginationTable from 'components/PaginationTable';
import { useDispatch, useSelector } from 'react-redux';
import { NewModel } from 'components/Modal';
import { Navigate } from 'react-router-dom';
import { getAllAdmins } from 'actions/adminActions';
import { adminRegister } from 'actions/adminActions';
import {
    Typography,
    useTheme,
    IconButton
} from "@mui/material";
import { Input } from 'components/input/input';
import { toast } from 'react-hot-toast';
import { MdRefresh } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { motion } from 'framer-motion';
import CryptoJS from 'crypto-js';

const Admins = () => {
    const authenticated = useSelector(state => state.auth.authenticated)
    const deleting = useSelector(state => state.admin.deleting)
    const loading = useSelector(state => state.admin.loading)
    const adding = useSelector(state => state.admin.adding)

    useEffect(() => {
        if (adding === true) {
            toast.loading('Processing...', {
                id: 'loading'
            })
        }
        else if (adding === false) {
            toast.dismiss('loading')
        }

    }, [adding]);

    useEffect(() => {
        if (loading === true) {
            toast.loading('Processing...', {
                id: 'loading'
            })
        }
        else if (loading === false) {
            toast.dismiss('loading')
        }

    }, [loading]);

    useEffect(() => {
        if (deleting === true) {
            toast.loading('Deleting...', {
                id: 'Deleting'
            })
        }
        else if (deleting === false) {
            toast.dismiss('Deleting')
        }

    }, [deleting]);

    const mode = useSelector(state => state.general.mode)

    useEffect(() => {
        dispatch(getAllAdmins())
    }, [mode])

    const theme = useTheme()
    const dispatch = useDispatch()
    const [email, setEmail] = useState(undefined);
    const [passwd, setPasswd] = useState(undefined);
    const [firstName, setFName] = useState(undefined);
    const [lastName, setLName] = useState(undefined);
    const [contact, setContact] = useState(undefined);
    const [occupation, setOccupation] = useState(undefined);

    const [pageSize, setPageSize] = useState(8);
    const admins = useSelector(state => state.admin.admins)
    const headings = [
        '#',
        'First Name',
        'Last Name',
        'Email',
        'Contact',
        'Actions'
    ]

    // Add Administrator
    const [showAddModal, setShowAddModal] = useState(false);

    const registerAdmin = (e) => {
        const hashPass = CryptoJS.SHA256(passwd).toString(CryptoJS.enc.Base64)
        const user = {
            firstName,
            lastName,
            email,
            hashPass,
            contact,
            occupation
        }
        if (user.firstName === '' || user.lastName === '' || user.email === '' || user.passwd === '' || user.occupation === undefined) {
            toast.error("Please fill required details")
        }
        else {
            dispatch(adminRegister(user))
        }

        setEmail('')
        setContact('')
        setFName('')
        setLName('')
        setPasswd('')
        setOccupation('')
        setShowAddModal(false)
    }

    const addModalClose = () => {
        setEmail('')
        setContact('')
        setFName('')
        setLName('')
        setPasswd('')
        setOccupation('')
        setShowAddModal(false)
    }

    const renderAddAdmins = () => {
        return (
            <NewModel
                show={showAddModal}
                close={addModalClose}
                handleClose={registerAdmin}
                ModalTitle="Add New Admin"
                size='md'
            >
                <Typography sx={{ color: theme.palette.primary[600] }}>
                    <Row>
                        <Col md={6}>
                            <Input
                                label="First Name"
                                value={firstName}
                                placeholder={'First Name'}
                                onChange={(e) => setFName(e.target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label="Last Name"
                                value={lastName}
                                placeholder={'Last Name'}
                                onChange={(e) => setLName(e.target.value)}
                            />
                        </Col>
                        <Col md={12}>
                            <Input
                                label="Email"
                                value={email}
                                placeholder={'Email'}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Col>

                        <Col md={6}>
                            <Input
                                label="Contact No"
                                value={contact}
                                placeholder={'Contact No'}
                                onChange={(e) => setContact(e.target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <label className='key' htmlFor='role' >Role</label>
                            <select
                                style={{ marginTop: '.5rem' }}
                                id='role'
                                label="Role"
                                className='form-control'
                                value={occupation}
                                onChange={(e) => setOccupation(e.target.value)} >
                                <option>Select Role</option>
                                <option value="Super Admin" >Super Admin</option>
                                <option value="Administrator" >Administrator</option>
                            </select>
                        </Col>
                    </Row>



                    <Input
                        label="Password"
                        value={passwd}
                        placeholder={'Password'}
                        onChange={(e) => setPasswd(e.target.value)}
                        type='password'
                    />
                </Typography>
            </NewModel>
        )
    }
    if (!authenticated) {
		return <Navigate to='/' />
	}

    return (
        <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: .75, ease: 'easeInOut' }}
            initial={{ opacity: 0 }}
        >
            <Container>
                <Row>
                    <Col md={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '50px' }} >
                            <Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
                                ADMINS
                            </Typography>
                            <button className='form-control' style={{ width: 'auto', margin: '0 10px' }} onClick={() => setShowAddModal(!showAddModal)}>Add Administrator</button>
                        </div>
                        <div style={{ display: 'flex', height: '2rem', alignItems: 'center' }}>
                            <IconButton onClick={() => dispatch(getAllAdmins())}>
                                <MdRefresh />
                            </IconButton>
                            <Typography onClick={() => dispatch(getAllAdmins())} style={{ cursor: 'pointer' }} >Refresh</Typography>
                        </div>
                    </Col>
                </Row>
                <Row style={{ display: 'flex' }}>
                    {
                        loading ?
                            <div style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color={theme.palette.primary[200]}
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div> :
                            admins.length > 0 ?
                                <motion.div
                                    whileInView={{ opacity: [0, 1] }}
                                    transition={{ duration: .75, ease: 'easeInOut' }}
                                    initial={{ opacity: 0 }}
                                >
                                    <Col md={12}>
                                        <PaginationTable headings={headings} pageSize={pageSize} Array={admins} theme={theme} />
                                    </Col>
                                </motion.div>
                                :
                                <motion.div
                                    whileInView={{ opacity: [0, 1] }}
                                    transition={{ duration: .75, ease: 'easeInOut' }}
                                    initial={{ opacity: 0 }}
                                    style={{ height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h1" fontWeight="bold" sx={{ textAlign: 'center', color: theme.palette.secondary[400] }} >
                                        No Administrator Accounts
                                    </Typography>
                                </motion.div>


                    }
                </Row>

            </Container>
            {renderAddAdmins()}
        </motion.div>
    );
}

export default Admins;
