import { adminConsts } from "actions/constants";

const initState = {
    admins: [],
    loading: false,
    deleting: false,
    adding: false
}

export default (state = initState, action) => {
    switch (action.type) {
        default:
            state = {
                ...initState
            }
            break
        case adminConsts.ADMIN_REGISTRATION_REQUEST:
            state = {
                ...state,
                adding: true
            }
            break
        case adminConsts.ADMIN_REGISTRATION_SUCCESS:
            state = {
                ...state,
                admins: action.payload,
                adding: false
            }
            break
        case adminConsts.ADMIN_REGISTRATION_FAILED:
            state = {
                ...state,
                adding: false
            }
            break
        case adminConsts.GET_ALL_ADMINS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case adminConsts.GET_ALL_ADMINS_SUCCESS:
            state = {
                ...state,
                admins: action.payload,
                loading: false
            }
            break
        case adminConsts.GET_ALL_ADMINS_FAILED:
            state = {
                ...state,
                loading: false
            }
            break
        case adminConsts.ADMIN_REMOVE_REQUEST:
            state = {
                ...state,
                deleting: true
            }
            break
        case adminConsts.ADMIN_REMOVE_SUCCESS:
            state = {
                ...state,
                deleting: false,
                admins: action.payload
            }
            break
        case adminConsts.ADMIN_REMOVE_FAILED:
            state = {
                ...state,
                deleting: false
            }
            break
    }
    return state
}