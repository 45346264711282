import axiosInstance from "../helpers/axios"
import { customerConsts } from "./constants"
import { toast } from "react-hot-toast"


export const getAllCustomers = () => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_GET_ALL_REQUEST
        })
        const res = await axiosInstance.get("/customer/getall")
        if (res.status === 200) {
            const customers = res.data.payload;
            toast.success("Customer list updated!", { id: 'cup' })
            dispatch({
                type: customerConsts.CUSTOMER_GET_ALL_SUCCESS,
                payload: customers,
            });
        }
        else {
            dispatch({
                type: customerConsts.CUSTOMER_GET_ALL_FAILED,
            });
        }
    }
}

export const getAllCustomers1 = () => {
    return async dispatch => {
        const res = await axiosInstance.get("/customer/getall")
        if (res.status === 200) {
            const customers = res.data.payload;
            dispatch({
                type: customerConsts.CUSTOMER_GET_ALL_SUCCESS,
                payload: customers,
            });
        }
        else {
            dispatch({
                type: customerConsts.CUSTOMER_GET_ALL_FAILED,
            });
        }
    }
}

export const addCustomer = (form) => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_ADD_REQUEST
        })
        const res = await axiosInstance.post("/customer/register", form)
        if (res.status === 201) {
            toast.success("Customer Registered")
            dispatch({
                type: customerConsts.CUSTOMER_ADD_SUCCESS,
                payload: res.data.payload
            })
        }
        else if (res.response.status === 401) {
            toast.error("Customer Already Registerd")
            dispatch({
                type: customerConsts.CUSTOMER_ADD_FAILED
            })
        }
        else {
            toast.error("Something Went Wrong")
            dispatch({
                type: customerConsts.CUSTOMER_ADD_FAILED
            })
        }
    }
}

export const updateCustomer = (form) => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_UPDATE_REQUEST
        })
        const res = await axiosInstance.post('/customer/update', form)

        if (res.status === 201) {
            toast.success("Customer Updated", { id: 'cUpS' })
            dispatch({
                type: customerConsts.CUSTOMER_UPDATE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if (res.response.status === 500) {
            toast.error("Something Went Wrong")
            dispatch({
                type: customerConsts.CUSTOMER_UPDATE_FAILED
            })
        }
        else if (res.response.status === 401) {
            toast.error("Customer not found")
            dispatch({
                type: customerConsts.CUSTOMER_UPDATE_FAILED
            })
        }
    }
}

export const removeCustomer = (CID) => {
    const form = {
        'CID': CID
    }
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_REMOVE_REQUEST
        })
        const res = await axiosInstance.post("/customer/remove", form)

        if (res.status === 201) {
            toast.success("Customer Removed")
            dispatch({
                type: customerConsts.CUSTOMER_REMOVE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if (res.response.status === 401) {
            toast.error("No Customer Account Found!!!")
            dispatch({
                type: customerConsts.CUSTOMER_REMOVE_FAILED
            })
        }
        else {
            toast.error("Something Went Wrong")
            dispatch({
                type: customerConsts.CUSTOMER_REMOVE_FAILED
            })
        }
    }
}

export const updateCustomerStatus = (form) => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_REQUEST
        })
        const res = await axiosInstance.post("/customer/status-update", form)
        if (res.status === 201) {
            toast.success("Customer Status Updated")
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_SUCCESS,
                payload: res.data.payload
            })
        }
        else if (res.response.status === 401) {
            toast.error("No customer account found")
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_FAILED
            })
        }
        else {
            toast.error("Something went wrong")
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_FAILED
            })
        }
    }
}

export const getCustomerDetails = (form) => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_DETAILS_FETCH_REQUEST
        })
        const res = await axiosInstance.post("/customer/getCustDetails", form)

        if (res.status === 200) {
            dispatch({
                type: customerConsts.CUSTOMER_DETAILS_FETCH_SUCCESS,
                payload: res.data.payload
            })
            toast.success("Customer data fetch success", { id: 'CDFS' })
        }
        else if (res.respose) {
            dispatch({
                type: customerConsts.CUSTOMER_DETAILS_FETCH_FAILED
            })
            toast.error(res.response.data.message)
        }
    }
}

export const customerAccountStatusUpdate = (form) => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_REQUEST
        })
        const res = await axiosInstance.post("/customer-account/acc-status-update", form)
        if (res.status === 201) {
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_SUCCESS,
                payload: res.data.payload
            })
            toast.success("Customer Account Status Updated")
        }
        else {
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_STATUS_UPDATE_FAILED
            })
            toast.error("Something went wrong")
        }
    }
}

export const deleteCustomerAccount = (form) => {
    return async dispatch => {
        dispatch({
            type: customerConsts.CUSTOMER_ACCOUNT_DELETE_REQUEST
        })
        const res = await axiosInstance.post("/customer-account/delete-account", form)
        if (res.status === 201) {
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_DELETE_SUCCESS,
                payload: res.data.payload
            })
            toast.success("Customer Account Deleted")
        }
        else {
            dispatch({
                type: customerConsts.CUSTOMER_ACCOUNT_DELETE_FAILED
            })
            toast.error("Something went wrong")
        }
    }
}